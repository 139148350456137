import { Component, Vue, Prop } from 'vue-property-decorator'
import { getSecretImage, getSecretVideo } from '@/libs/cos'
import moment, { Moment } from 'moment'
import _ from 'lodash'
import Big from 'big.js'
import MyConfig from '@/config'

const numeral = require('numeral')

@Component
export default class MyCommon extends Vue {
  confirmLoading = false

  loading = false

  skeLoading = false

  spinLoading = false

  spinContent = '请稍后'

  x0 = 0

  y0 = 0

  x1 = 0

  y1 = 0

  env: any = MyConfig.env

  datePickerRanges: any = {
    今天: [this.getStartTime(moment()), this.getEndTime(moment())],
    昨天: [
      this.getStartTime(moment().subtract(1, 'day')),
      this.getEndTime(moment().subtract(1, 'day')),
    ],
    最近7天: [
      this.getStartTime(moment().subtract(6, 'days')),
      this.getEndTime(moment()),
    ],
    最近30天: [
      this.getStartTime(moment().subtract(29, 'days')),
      this.getEndTime(moment()),
    ],
    本月: [
      this.getStartTime(moment().startOf('month')),
      this.getEndTime(moment().endOf('month')),
    ],
  }

  weeks: any[] = [
    { key: '1', name: '周一' },
    { key: '2', name: '周二' },
    { key: '3', name: '周三' },
    { key: '4', name: '周四' },
    { key: '5', name: '周五' },
    { key: '6', name: '周六' },
    { key: '0', name: '周日' },
  ]

  typedata: any[] = []

  smsPlatformOption: any[] = [
    { id: '1', name: '腾讯' },
    { id: '2', name: '云片' },
    { id: '3', name: '上亿' },
  ]

  cardTypes: any[] = [
    { id: '1', name: '储值卡' },
    { id: '2', name: '折扣卡' },
    { id: '3', name: '储值+折扣卡' },
    { id: '4', name: '包年/包月卡' },
  ]

  settlementVType: any[] = [
    { name: '%', value: '0' },
    { name: '元', value: '1' },
    { name: '‰', value: '2' },
    { name: '元每笔', value: '3' },
  ]

  oplatformAppTypes: any[] = [
    { value: '0', name: '第三方平台' },
    { value: '1', name: '公众号' },
    { value: '2', name: '小程序' },
    { value: '3', name: '网站应用' },
  ]

  tableSize = 'middle'

  modalVisible = true

  @Prop() handleCancel!: (...args: any[]) => any

  form!: any

  moment: any = moment

  renderWeekName(d?: any) {
    if (d) {
      const obj = _.find(this.weeks, (o) => {
        return Number(o.key) === Number(moment(d).weekday())
      })

      if (obj) {
        return obj.name
      }
    }

    const obj = _.find(this.weeks, (o) => {
      // console.log(moment().weekday())
      // console.log(o.key)
      return Number(o.key) === Number(moment().weekday())
    })
    // console.log(obj)

    if (obj) {
      return obj.name
    }
  }

  formItemLayout: any = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  }

  formItemLayout2: any = {
    labelCol: { span: 2 },
    wrapperCol: { span: 19 },
  }

  formTailLayout: any = {
    labelCol: { span: 6 },
    wrapperCol: { span: 8, offset: 4 },
  }

  imgrotate = 0

  tsMul(num1: number, num2: number): number {
    return Big(num1)
      .mul(num2)
      .toNumber()
  }

  async loadAuthUserGroups() {
    const res = await this.$store.dispatch('api/authusergroups', {})
    if (res && res.Data) {
      this.typedata = res.Data
    }
  }

  /**
   * 显示用户详情
   * @param id 用户id
   * @returns void
   */
  showGlobalUserDetailModal(id: number) {
    this.$store.dispatch('showUserDetailModal', id)
  }

  /**
   * 隐藏用户详情
   * @returns void
   */
  hideGlobalUserDetailModal() {
    this.$store.dispatch('hideUserDetailModal')
  }

  showGlobalBillDetailModal(id: number) {
    this.$store.dispatch('showBillDetailModal', id)
  }

  hideGlobalBillDetailModal() {
    this.$store.dispatch('hideBillDetailModal')
  }

  showGlobalLineChartModal(params: any) {
    // console.log(params)
    this.$store.dispatch('showLineChartModal', params)
  }

  hideGlobalLineChartModal() {
    this.$store.dispatch('hideLineChartModal')
  }

  /**
   * 拼接日期的00:00:00
   * @param m 日期
   */
  getStartTime(m: any): any {
    return moment(`${m.format('YYYY-MM-DD')} 00:00:00`)
  }

  /**
   * 拼接日期的23:59:59
   * @param m 日期
   */
  getEndTime(m: any): any {
    return moment(`${m.format('YYYY-MM-DD')} 23:59:59`)
  }

  /**
   * 获取隐私存储桶路径
   * @param args 参数
   * @return 路径
   */
  getSecretVideo: (...args: any[]) => any = getSecretVideo

  beforeCreate() {
    // this.$message.config({
    //   top: '100px',
    //   duration: 2,
    //   maxCount: 3,
    // })

    this.form = this.$form.createForm(this, {
      // onValuesChange: (props, values) => {
      //     console.log(props)
      //     console.log(values)
      // }
    })
  }

  /**
   * 前端判断权限
   * @param permKey 权限key
   */
  checkPermission(permKey: string) {
    // console.log(this.$store.state.user.userPerms)
    if (_.has(this.$store.state.user.userPerms, permKey)) {
      return true
    }
    return false
  }

  /**
   * 格式化输出数值
   * @param num 数值
   */
  renderNumber(num: any) {
    return numeral(num).format('0,0')
  }

  /**
   * 格式化输出金额
   * @param money 金额
   */
  renderMoney(money: any) {
    return numeral(money).format('0,0.00')
  }

  renderTime(dtime: string) {
    return moment(dtime).unix()
  }

  /**
   * 渲染折扣
   * @param discount 折扣
   */
  renderDiscount(discount: number) {
    if (discount === undefined) {
      // eslint-disable-next-line no-param-reassign
      discount = 100
    }
    if (discount === 100) {
      return '订场不打折'
    }
    if (discount === 0) {
      return '订场0折'
    }
    return `订场${discount / 10}折`
  }

  /**
   * 输出表单值
   * @param val 值
   * @param def 默认值
   */
  renderFormValue(val: any, def: any) {
    if (!_.isUndefined(val) && val) {
      return val
    }
    return def || ''
  }

  /**
   * 输出时间
   * @param date 时间
   */
  renderDate(date: any) {
    if (!_.isEmpty(date)) {
      if (moment.isMoment(date)) {
        return date
      }
      return moment(date)
    }
    return null
  }

  /**
   * 输出图片路径
   * @param img 图片path
   * @returns 图片路径
   */
  renderImage(img: string) {
    if (img) {
      if (img.indexOf('http') !== -1) {
        return img
      }
      if (img) {
        return `https://data.taisam.cn${img}`
      }
      return 'https://data.taisam.cn/assets/noimage.png'
    }
    return 'https://data.taisam.cn/assets/noimage.png'
  }

  renderMsgContent(msgcontent: string) {
    if (msgcontent === '') {
      return []
    }

    if (_.startsWith(msgcontent, ',')) {
      return _.trim(msgcontent, ',').split(',')
    }
    return []
  }

  renderObjValue(obj: any, key: string, val: any, showkey: string) {
    const obj1 = _.find(obj, (o) => o[key] == val)
    if (obj1) {
      return obj1[showkey]
    }
    return ''
  }

  renderRightText(obj: any, field: any) {
    if (field && field.length > 0) {
      const val = _.get(obj, field, '')
      if (val == null) {
        return ''
      }
      return String(val)
    }
    return obj ? String(obj) : ''
  }

  openVideoToNewBroswer(path = '') {
    getSecretVideo(path).then((url) => {
      window.open(url)
    })
  }

  async getImageUrl(path = '', name = '') {
    if (!path) {
      return Promise.resolve('')
    }

    return getSecretImage(path).then((url) => Promise.resolve(url))
  }

  commonUrl(uri: string) {
    if (uri) {
      return `https://data.taisam.cn${uri}`
    }
    return 'https://data.taisam.cn/assets/noimage.png'
  }

  showImage(url: string) {
    this.imgrotate = 0
    const h = this.$createElement
    this.$info({
      title: '图片详情',
      width: 1000,
      content: h(
        'div',
        {
          style: {
            marginLeft: '-38px',
          },
        },
        [
          h(
            'div',
            {
              style: {
                textAlign: 'center',
              },
            },
            [
              h(
                'a-button',
                {
                  props: {
                    type: 'primary',
                  },
                  on: {
                    click: this.rotateImage,
                  },
                },
                '旋转'
              ),
              h(
                'a-button',
                {
                  props: {
                    type: 'primary',
                  },
                  on: {
                    click: this.rotateYImage,
                  },
                },
                '翻转'
              ),
              h(
                'a-button',
                {
                  props: {
                    type: 'primary',
                  },
                  on: {
                    click: this.zoomImage,
                  },
                },
                '放大'
              ),
              h(
                'a-button',
                {
                  props: {
                    type: 'primary',
                  },
                  on: {
                    click: this.zoomInImage,
                  },
                },
                '缩小'
              ),
            ]
          ),
          h(
            'div',
            {
              style: {
                height: '700px',
                overflow: 'auto',
              },
            },
            [
              h(
                'div',
                {
                  ref: 'imgwrap',
                },
                [
                  h('img', {
                    ref: 'viewimg',
                    attrs: {
                      src: this.renderImage(url),
                    },
                    style: {
                      // maxWidth: '100%'
                    },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
      okText: '关闭',
    })
  }

  // 旋转图片
  rotateImage() {
    const img: any = this.$refs.viewimg
    if (img) {
      const img1: any = window.getComputedStyle(img)

      const width: any = img1.width
      const height: any = img1.height

      const oWidth = Number(width.replace('px', ''))
      const oHeight = Number(height.replace('px', ''))
      this.imgrotate += 90
      if (this.imgrotate >= 360) {
        this.imgrotate = 0
      }
      img.style.transform = `rotate(${this.imgrotate}deg)`
      const { imgwrap }: any = this.$refs
      if (oWidth > oHeight) {
        // 横的长方形
        imgwrap.style.paddingTop = '200px'
      } else {
        // 竖的长方形
        imgwrap.style.paddingLeft = '200px'
      }
    }
  }

  rotateYImage() {
    const img: any = this.$refs.viewimg
    this.imgrotate += 180
    if (this.imgrotate >= 360) {
      this.imgrotate = 0
    }
    img.style.transform = `rotateY(${this.imgrotate}deg)`
  }

  zoomImage() {
    const img: any = this.$refs.viewimg
    if (img) {
      const img1 = window.getComputedStyle(img)
      if (img1) {
        const width: any = img1.width
        const height: any = img1.height
        const oWidth = Number(width.replace('px', ''))
        const oHeight = Number(height.replace('px', ''))

        img.style.width = `${oWidth + 100}px`
        img.style.height = `${oHeight + 100 / (oWidth / oHeight)}px`
      }
    }
  }

  zoomInImage() {
    const img: any = this.$refs.viewimg
    if (img) {
      const img1: any = window.getComputedStyle(img)
      if (img1) {
        const width: any = img1.width
        const height: any = img1.height
        const oWidth = Number(width.replace('px', ''))
        const oHeight = Number(height.replace('px', ''))

        img.style.width = `${oWidth - 100}px`
        img.style.height = `${oHeight - 100 / (oWidth / oHeight)}px`
      }
    }
  }

  renderMobile(mobile: string) {
    return mobile.substring(0, 3) + '****' + mobile.substring(7)
  }

  copySuccess() {
    this.$message.success('复制成功')
  }

  /**
   * showTsConfirm 显示自定义确认框
   * @param params 参数
   * @return void
   */
  showTsConfirm(params: any) {
    this.$store.dispatch('showConfirmModal', params)
  }

  /**
   * hideTsConfirm 关闭自定义确认框
   */
  hideTsConfirm() {
    this.$store.dispatch('hideConfirmModal')
  }

  /**
   * 保存原因
   * @param params 原因
   */
  saveOperationReason(params: any) {
    // console.log(params)
    this.$store.dispatch('saveOperationReason', params)
  }

  // 改变一个字段
  // @param form 表单
  // @param params 参数
  // @param permKey 权限key
  async changeFieldValue(form: any, params: any, permKey?: string) {
    this.$nextTick(() => {
      params.newval = form.getFieldValue(params.fieldname)

      let isboolean = false

      if (typeof params.newval === 'boolean') {
        isboolean = true
        params.newval = params.newval ? 0 : 1
      } else if (moment.isMoment(params.newval)) {
        params.newval = moment(params.newval).format('YYYY-MM-DD HH:mm:ss')
      }

      // if (_.isEqual(params.newval, params.oldval)) {
      //     return
      // }

      const cb: any = (err: any, values: any) => {
        if (!err) {
          this.showTsConfirm({
            title: '确认信息',
            content: `确定${params.content}吗？`,
            onOk: async () => {
              if (permKey) {
                // 需要判断权限
                if (!this.checkPermission(permKey)) {
                  this.$message.warning('没有权限')
                  return
                }
              }

              if (_.has(params, 'columnname')) {
                // 当有传columnname时，后台更新的字段以columnname为准
                params.fieldname = params.columnname
              }
              // console.log(this.$store.state)
              const res = await this.$store.dispatch(
                'api/changeFieldValue',
                params
              )
              // console.log(res)
              if (res && parseInt(res.Code, 10) === 200) {
                this.$message.success(res.Message)
              } else {
                this.$message.error(res.Message)
              }
            },
            onCancel: () => {
              if (isboolean) {
                form.setFieldsValue({
                  [params.fieldname]: params.oldval === '1',
                })
              } else {
                form.setFieldsValue({
                  [params.fieldname]: params.oldval,
                })
              }
            },
          })
        }
      }

      if (params.isRequired) {
        form.validateFields([params.fieldname], { force: true }, cb)
        return
      }

      cb(false, {})
    })
  }

  // 获取日历的开始日期和结束日期
  calendarDate(date: string): string[] {
    const timeForWork = moment(date)

    const weekFirstDay = parseInt(timeForWork.startOf('month').format('d'), 10)

    const weekLastDay = parseInt(timeForWork.endOf('month').format('d'), 10)
    const monthLastDay = parseInt(timeForWork.endOf('month').format('D'), 10)

    const days: string[] = []

    // 上个月
    let lastMonthDay = 0
    for (let i = 0; i < weekFirstDay; i += 1) {
      lastMonthDay += 1
    }

    // moment默认周日是第一天
    days.push(
      timeForWork
        .startOf('month')
        .clone()
        .subtract(lastMonthDay - 1, 'day')
        .format('YYYY-MM-DD')
    )

    // 本月
    // for (let i = 1; i <= monthLastDay; i += 1) {

    // }

    // 下个月
    let nextMonthDay = 0
    for (let i = weekLastDay; i < 6; i += 1) {
      nextMonthDay += 1
    }

    days.push(
      timeForWork
        .endOf('month')
        .clone()
        .add(nextMonthDay + 1, 'day')
        .format('YYYY-MM-DD')
    )

    // console.log(days)

    return days
  }
}
