import axios from '@/request'
import axiosRetry from 'axios-retry'
import qs from 'qs'

axiosRetry(axios, { retries: 3 })

export default {
  async list(params: any) {
    return axios.get('user/list', { params })
  },
  async vipcard(params: any) {
    return axios.get('user/vip-card', { params })
  },
}
