










































import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'
// import VueEcharts from 'vue-echarts-ts'
// import Echarts from 'echarts'

import {
  use,
  registerMap,
  registerTheme,
  connect,
  disconnect,
} from 'echarts/core'
import {
  BarChart,
  LineChart,
  PieChart,
  MapChart,
  RadarChart,
  ScatterChart,
  EffectScatterChart,
  LinesChart,
} from 'echarts/charts'
import {
  GridComponent,
  PolarComponent,
  GeoComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  ToolboxComponent,
  DataZoomComponent,
} from 'echarts/components'

import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'

import VChart, { THEME_KEY } from 'vue-echarts'

import 'echarts-gl'
// import 'echarts/map/js/china'

const Timer = require('timer.js')
import moment from 'moment'
import 'moment-timer'

import _ from 'lodash'
import config from '@/config'

const numeral = require('numeral')

require('@/utils/echarts-auto-tooltip')

use([
  BarChart,
  LineChart,
  PieChart,
  MapChart,
  RadarChart,
  ScatterChart,
  EffectScatterChart,
  LinesChart,
  GridComponent,
  PolarComponent,
  GeoComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  CanvasRenderer,
  SVGRenderer,
  ToolboxComponent,
  DataZoomComponent,
])

// Vue.use(VueEcharts)

// _.set(window, ['geoJson = require('echarts/map/json/china.json')
// @ts-ignore
// _.set(window, ['geoJson = import('@/utils/100000_geojson_full')
// require('echarts/map/js/china')
// _.set(window, ['geoJson = require('/data/')
// console.log(geoJson)
// require('echarts-amap')
_.set(window, ['geo100000'], require('@/store/data/100000_geojson_full.json'))
_.set(window, ['geo110000'], require('@/store/data/110000_geojson_full.json'))
_.set(window, ['geo120000'], require('@/store/data/120000_geojson_full.json'))
_.set(window, ['geo130000'], require('@/store/data/130000_geojson_full.json'))
_.set(window, ['geo140000'], require('@/store/data/140000_geojson_full.json'))
_.set(window, ['geo150000'], require('@/store/data/150000_geojson_full.json'))
_.set(window, ['geo210000'], require('@/store/data/210000_geojson_full.json'))
_.set(window, ['geo220000'], require('@/store/data/220000_geojson_full.json'))
_.set(window, ['geo230000'], require('@/store/data/230000_geojson_full.json'))
_.set(window, ['geo310000'], require('@/store/data/310000_geojson_full.json'))
_.set(window, ['geo320000'], require('@/store/data/320000_geojson_full.json'))
_.set(window, ['geo330000'], require('@/store/data/330000_geojson_full.json'))
_.set(window, ['geo340000'], require('@/store/data/340000_geojson_full.json'))
_.set(window, ['geo350000'], require('@/store/data/350000_geojson_full.json'))
_.set(window, ['geo360000'], require('@/store/data/360000_geojson_full.json'))
_.set(window, ['geo370000'], require('@/store/data/370000_geojson_full.json'))
_.set(window, ['geo410000'], require('@/store/data/410000_geojson_full.json'))
_.set(window, ['geo420000'], require('@/store/data/420000_geojson_full.json'))
_.set(window, ['geo430000'], require('@/store/data/430000_geojson_full.json'))
_.set(window, ['geo440000'], require('@/store/data/440000_geojson_full.json'))
_.set(window, ['geo450000'], require('@/store/data/450000_geojson_full.json'))
_.set(window, ['geo460000'], require('@/store/data/460000_geojson_full.json'))
_.set(window, ['geo500000'], require('@/store/data/500000_geojson_full.json'))
_.set(window, ['geo510000'], require('@/store/data/510000_geojson_full.json'))
_.set(window, ['geo520000'], require('@/store/data/520000_geojson_full.json'))
_.set(window, ['geo530000'], require('@/store/data/530000_geojson_full.json'))
_.set(window, ['geo540000'], require('@/store/data/540000_geojson_full.json'))
_.set(window, ['geo610000'], require('@/store/data/610000_geojson_full.json'))
_.set(window, ['geo620000'], require('@/store/data/620000_geojson_full.json'))
_.set(window, ['geo630000'], require('@/store/data/630000_geojson_full.json'))
_.set(window, ['geo640000'], require('@/store/data/640000_geojson_full.json'))
_.set(window, ['geo650000'], require('@/store/data/650000_geojson_full.json'))
_.set(window, ['geo710000'], require('@/store/data/710000_geojson_full.json'))
_.set(window, ['geo810000'], require('@/store/data/810000_geojson_full.json'))
_.set(window, ['geo820000'], require('@/store/data/820000_geojson_full.json'))

@Component({
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: 'light',
  },
})
export default class extends Mixins() {
  @Prop() showStadium!: (...args: any[]) => any
  @Prop() maptotal!: any
  @Prop() loadMapTotal!: (...args: any[]) => any

  breadcrumbs: any[] = [
    {
      id: 0,
      name: '全国',
    },
  ]

  locationPos: string = '全国'

  ei: any = {}

  t1!: any
  t2!: any
  t3!: any
  t4!: any
  t5!: any

  autotip!: any

  curtime: string = ''

  params: any = {
    province_id: '',
    city_id: '',
    county_id: '',
  }

  option: any = {
    tooltip: {
      trigger: 'item',
      position: 'left',
      backgroundColor: 'rgba(50, 50, 50, 0.7)',
      borderColor: 'rgba(50, 50, 50, 0.7)',
      textStyle: { color: 'rgba(255, 255, 255, 1)' },
      formatter: (params: any) => {
        return `<span style="color:${params.color}">${params.seriesName}</span>
        <br />场馆名称：${params.data.name}<br />场馆地址：${params.data.addr}<br />订场电话：${params.data.phone}`
      },
    },
    geo: {
      // map: 'mapCity',
      roam: true,
      top: '30%',
      bottom: '-12%',
      label: {
        show: true,
        color: 'rgb(255,255,255, 0.5)',
        emphasis: {
          color: 'white',
          show: false,
        },
      },
      itemStyle: {
        normal: {
          borderColor: 'rgba(114, 203, 255, 1)',
          borderWidth: 1,
          areaColor: {
            colorStops: [
              {
                offset: 0,
                color: 'rgba(147, 235, 248, 0)', // 0% 处的颜色
              },
            ],
            globalCoord: false, // 缺省为 false
          },
        },
        emphasis: {
          areaColor: '#389BB7',
          borderWidth: 0,
        },
      },
    },
    series: [
      {
        name: '已运营智能场馆',
        roam: true,
        data: [],
        type: 'scatter',
        coordinateSystem: 'geo',
        label: {
          normal: {
            show: false,
            position: 'right',
            formmater: '{b}',
          },
          emphasis: {
            show: false,
          },
        },
        itemStyle: {
          color: 'rgba(243, 234, 85, 0.95)',
        },
        symbolSize: 10,
        zlevel: 4,
      },
      {
        name: '待升级智能场馆',
        roam: true,
        data: [],
        type: 'scatter',
        coordinateSystem: 'geo',
        label: {
          normal: {
            show: false,
            position: 'right',
            formmater: '{b}',
          },
          emphasis: {
            show: false,
          },
        },
        itemStyle: {
          color: 'rgba(194, 204, 43, 0.95)',
        },
        symbolSize: 10,
        zlevel: 3,
      },
      {
        name: '已认证订场平台',
        roam: true,
        data: [],
        type: 'scatter',
        coordinateSystem: 'geo',
        label: {
          normal: {
            show: false,
            position: 'right',
            formmater: '{b}',
          },
          emphasis: {
            show: false,
          },
        },
        itemStyle: {
          color: 'rgba(129,94,254, 0.95)',
        },
        symbolSize: 6,
        zlevel: 2,
      },
      {
        name: '待认证订场平台',
        roam: true,
        data: [],
        type: 'scatter',
        coordinateSystem: 'geo',
        label: {
          normal: {
            show: false,
            position: 'right',
            formmater: '{b}',
          },
          emphasis: {
            show: false,
          },
        },
        itemStyle: {
          color: 'rgba(129, 94, 254, 0.5)',
        },
        symbolSize: 6,
        zlevel: 1,
      },
      {
        type: 'effectScatter',
        roam: true,
        coordinateSystem: 'geo',
        data: [],
        rippleEffect: {
          brushType: 'stroke',
          scale: 5,
        },
        // symbolSize: 30,
        hoverAnimation: true,
        tooltip: {
          normal: {
            show: false,
          },
          emphasis: {
            show: false,
          },
        },
        label: {
          normal: {
            show: true,
            zlevel: 99999,
            formatter: (params: any) => {
              return (
                '{fline|球友：' +
                params.data.name +
                '}\n{tline|在' +
                params.data.stadium_name +
                '订场}'
              )
            },
            position: 'top',
            backgroundColor: 'rgba(8,186,236,.9)',
            padding: [0, 0],
            borderRadius: 3,
            lineHeight: 32,
            color: '#ffffff',
            rich: {
              fline: {
                padding: [0, 10, 10, 10],
                color: '#ffffff',
              },
              tline: {
                padding: [10, 10, 0, 10],
                color: '#ffffff',
              },
            },
          },
          emphasis: {
            show: true,
          },
        },
        // tooltip: {
        //   position: 'top',
        //   formatter: (params: any) => {
        //     return `球友：${params.data.name}<br />在${params.data.stadium_name}订场`
        //   }

        // },
        itemStyle: {
          normal: {
            color: 'rgba(8,186,236,.9)',
            shadowBlur: 10,
            shadowColor: '#333',
          },
        },
        zlevel: 5,
      },
      {
        name: '升级中智能场馆',
        roam: true,
        data: [],
        type: 'scatter',
        coordinateSystem: 'geo',
        label: {
          normal: {
            show: false,
            position: 'right',
            formmater: '{b}',
          },
          emphasis: {
            show: false,
          },
        },
        itemStyle: {
          color: 'rgba(251, 171, 56, 0.95)',
        },
        symbolSize: 10,
        zlevel: 2,
      },
      {
        name: '待跟进智能场馆',
        roam: true,
        data: [],
        type: 'scatter',
        coordinateSystem: 'geo',
        label: {
          normal: {
            show: false,
            position: 'right',
            formmater: '{b}',
          },
          emphasis: {
            show: false,
          },
        },
        itemStyle: {
          color: 'rgba(96,187,231, 0.95)',
        },
        symbolSize: 10,
        zlevel: 2,
      },
    ],
  }

  @Watch('computedLatestBillList')
  watchComputedLatestBillList(newVal: any, oldVal: any) {
    if (!_.isEqual(newVal, oldVal)) {
      let res1: any[] = []
      for (const obj of newVal) {
        res1.push({
          name: obj.name,
          value: obj.value.split(','),
          stadium_name: obj.stadium_name,
          id: obj.id,
          addr: '',
          phone: obj.phone,
        })
      }

      // console.log(res1)

      if (res1.length > 0) {
        this.option.series[4].data = _.concat(this.option.series[4].data, res1)
        // 60秒后，从序列中移除
        const myTimer = new Timer({
          tick: 1,
          onend: () => {
            this.option.series[4].data = _.drop(
              this.option.series[4].data,
              res1.length
            )
            this.ei.setOption(this.option)
          },
        })
        myTimer.start(60)
        this.ei.setOption(this.option)
      }
    }
  }

  handleBack(e: any) {
    e.stopPropagation()
    // console.log(this.breadcrumbs)
    if (this.breadcrumbs.length === 4) {
      // 现在是区
      this.changeAreaData(this.breadcrumbs[2].id)
      this.city(this.breadcrumbs[2].id, this.breadcrumbs[2].name).then(() => {
        this.resetSeries()
        this.loadStadiumData({
          city_id: this.breadcrumbs[2].id,
        })
      })

      this.breadcrumbs = _.dropRight(this.breadcrumbs)
      // console.log(this.breadcrumbs)
      this.locationPos = this.breadcrumbs[this.breadcrumbs.length - 1].name
    } else if (this.breadcrumbs.length === 3) {
      // 现在是市了，需要退到省，移除最后一个元素
      this.changeAreaData(this.breadcrumbs[1].id)

      // console.log(this.breadcrumbs)
      this.province(this.breadcrumbs[1].id, this.breadcrumbs[1].name).then(
        () => {
          this.resetSeries()
          this.loadStadiumData({
            province_id: this.breadcrumbs[1].id,
          })
        }
      )
      // 获取最后一个

      this.breadcrumbs = _.dropRight(this.breadcrumbs)
      // console.log(this.breadcrumbs)
      this.locationPos = this.breadcrumbs[this.breadcrumbs.length - 1].name
    } else if (this.breadcrumbs.length === 2) {
      // 现在是省了，需要退到国，
      // console.log('国')
      this.changeAreaData(100000)
      this.country().then(() => {
        this.resetSeries()
        this.loadStadiumData()
      })

      this.breadcrumbs = _.dropRight(this.breadcrumbs)
      // console.log(this.breadcrumbs)
      this.locationPos = this.breadcrumbs[this.breadcrumbs.length - 1].name
    } else {
      this.locationPos = this.breadcrumbs[this.breadcrumbs.length - 1].name
    }
  }

  resetSeries() {
    this.option.series[0].data = []
    this.option.series[1].data = []
    this.option.series[2].data = []
    this.option.series[3].data = []
    // this.option.series[4].data = []
    this.option.series[5].data = []
    this.option.series[6].data = []
    this.ei.setOption(this.option)
  }

  zoomAnimation() {
    // let count = 0;
    // const zoom = (per: number) => {
    //   if (!count) count = per;
    //   count = count + per;
    //   // console.log(per,count);
    //   this.ei.setOption({
    //     geo: {
    //       zoom: count
    //     }
    //   });
    //   if (count < 1) window.requestAnimationFrame(function () {
    //     zoom(0.2);
    //   });
    // };
    // window.requestAnimationFrame(function () {
    //   zoom(0.2);
    // });
  }

  renderNum(num: number) {
    return numeral(num).format('0,0')
  }

  showProvince() {
    if (this.params.province_id || this.params.city_id) {
      // 当前是省或者是市
      return false
    } else {
      return true
    }
  }

  showCity() {
    if (this.params.city_id) {
      // 当前市
      return false
    }
    return true
  }

  changeAreaData(adcode1: any) {
    // console.log(adcode1)
    const adcode = adcode1.toString()
    // console.log(this.params)

    if (adcode === '100000') {
      this.params.province_id = ''
      this.params.city_id = ''
      this.params.county_id = ''
      this.loadMapTotal({
        province_id: '',
        city_id: '',
        county_id: '',
      })
    } else if (adcode.substring(2, 4) === '00') {
      // 省份
      this.params.province_id = adcode
      this.params.city_id = ''
      this.loadMapTotal({
        province_id: adcode,
        city_id: '',
      })
    } else if (parseInt(adcode.substring(4, 6), 10) > 0) {
      // 区
      // console.log('区')
      this.params.county_id = adcode
      this.loadMapTotal({
        county_id: adcode,
      })
    } else if (parseInt(adcode.substring(2, 4), 10) > 0) {
      // 市
      this.params.city_id = adcode
      this.loadMapTotal({
        city_id: adcode,
      })
    }
    // console.log(this.params);
  }

  async country() {
    // this.ei.showLoading('default', {
    //   text: '加载中',
    //   maskColor: 'rgba(255,255,255,1)',
    //   zlevel: 100
    // })
    this.ei.off('click')
    // const res = await this.axios.get('/data/100000_geojson_full.json'))
    // console.log(res)

    // if (res && res.status === 200 && res.data) {
    registerMap('china1', _.get(window, ['geo100000']))
    // console.log(this.option)
    this.option.geo.map = 'china1'
    this.option.geo.zoom = 1.7
    this.option.geo.top = '30%'
    this.option.geo.bottom = '-12%'
    // console.log(this.ei)
    // this.ei._componentsViews[0]._mapDraw._controllerHost.target._dirty = true
    this.ei.clear()
    this.ei.setOption(this.option)
    this.zoomAnimation()

    // console.log(this.ei.getOption())
    this.ei.on('click', (params: any) => {
      // console.log(params)
      if (params.componentType === 'series') {
        if (params.seriesIndex === 0) {
          this.showStadium(params.data.id)
        }
      }

      if (params.componentType === 'geo') {
        const provinceCode = _.get(window, ['geo100000']).features.filter(
          (val: any) => {
            if (val.properties.name.indexOf(params.name) !== -1) {
              return true
            }
          }
        )

        // console.log(provinceCode)

        this.locationPos = params.name

        this.breadcrumbs.push({
          type: 'province',
          id: provinceCode[0].properties.adcode,
          name: params.name,
        })
        this.option.geo.top = 0
        this.ei.setOption(this.option)
        this.changeAreaData(provinceCode[0].properties.adcode)

        // console.log(provinceCode)
        this.province(provinceCode[0].properties.adcode, params.name).then(
          () => {
            // 清除旧数据
            this.resetSeries()

            this.loadStadiumData({
              province_id: provinceCode[0].properties.adcode,
            })
          }
        )
      }
    })
    // }
    // this.ei.hideLoading()
  }

  async province(id: string, name: string) {
    // this.ei.showLoading('default', {
    //   text: '加载中',
    //   maskColor: 'rgba(255,255,255,1)',
    //   zlevel: 100
    // })
    this.ei.off('click')
    // const res = await this.axios.get(`/data/${id}_geojson_full.json`)
    // console.log(res)
    // if (res && res.status === 200 && res.data) {
    // console.log(id)
    // console.log(`geo${id}`)
    // @ts-ignore
    // console.log(window[`geo${id}`])
    // @ts-ignore
    registerMap('mapProvince', window[`geo${id}`])
    // this.option.geo.top = 0
    this.option.geo.map = 'mapProvince'
    this.option.geo.zoom = 1
    this.option.geo.bottom = 0
    this.option.geo.top = 0
    this.ei.clear()
    this.ei.setOption(this.option)
    this.zoomAnimation()

    this.ei.on('click', (params: any) => {
      // console.log(params)
      if (params.componentType === 'series') {
        if (params.seriesIndex === 0) {
          this.showStadium(params.data.id)
        }
      }
      if (params.componentType === 'geo') {
        const cityCode = _.get(window, [`geo${id}`]).features.filter(
          (val: any) => {
            if (val.properties.name.indexOf(params.name) !== -1) {
              return true
            }
          }
        )
        // console.log(cityCode)

        this.locationPos = params.name

        this.breadcrumbs.push({
          type: 'city',
          id: cityCode[0].properties.adcode,
          name: params.name,
        })

        this.changeAreaData(cityCode[0].properties.adcode)

        this.city(cityCode[0].properties.adcode, params.name).then(() => {
          // 清除旧数据
          this.resetSeries()

          this.loadStadiumData({
            city_id: cityCode[0].properties.adcode,
          })
        })
      }
    })
    // }
    // this.ei.hideLoading()
  }

  async city(id: string, name: string) {
    this.ei.showLoading('default', {
      text: '加载中',
      maskColor: 'rgba(255,255,255,1)',
      zlevel: 100,
    })
    this.ei.off('click')

    const res = await this.axios.get(`/data1/${id}_geojson_full.json`)
    // console.log(res)

    if (res && res.status === 200 && res.data) {
      registerMap('mapCity', res.data)
      this.option.geo.map = 'mapCity'
      this.option.geo.zoom = 1
      this.ei.clear()
      this.ei.setOption(this.option)
      this.zoomAnimation()

      this.ei.on('click', (params: any) => {
        if (params.componentType === 'series') {
          if (params.seriesIndex === 0) {
            this.showStadium(params.data.id)
          }
        }

        if (params.componentType === 'geo') {
          const countyCode = res.data.features.filter((val: any) => {
            if (val.properties.name.indexOf(params.name) !== -1) {
              return true
            }
          })

          this.locationPos = params.name

          this.breadcrumbs.push({
            type: 'county',
            id: countyCode[0].properties.adcode,
            name: params.name,
          })

          this.changeAreaData(countyCode[0].properties.adcode)

          this.county(countyCode[0].properties.adcode, params.name).then(() => {
            // 清除旧数据
            this.resetSeries()

            this.loadStadiumData({
              county_id: countyCode[0].properties.adcode,
            })
          })
        }
      })
    }
    this.ei.hideLoading()
  }

  async county(id: string, name: string) {
    this.ei.showLoading('default', {
      text: '加载中',
      maskColor: 'rgba(255,255,255,1)',
      zlevel: 100,
    })
    this.ei.off('click')

    const res = await this.axios.get(`/data/${id}_geojson.json`)
    console.log(res)

    if (res && res.status === 200 && res.data) {
      registerMap('mapCounty', res.data)
      this.option.geo.map = 'mapCounty'

      this.ei.clear()
      this.ei.setOption(this.option)
      // console.log(this.option)
      this.zoomAnimation()

      this.ei.on('click', (params: any) => {
        if (params.componentType === 'series') {
          if (params.seriesIndex === 0) {
            this.showStadium(params.data.id)
          }
        }
      })
    }
    this.ei.hideLoading()
  }

  async loadStadium1(params?: any) {
    const res1: any[] = []
    const res = await this.$store.dispatch(`datav/stadium1`, { ...params })
    // console.log(res)
    if (res && res.Data) {
      for (const obj of res.Data) {
        // obj.value += ",1"
        res1.push({
          id: obj.id,
          name: obj.name,
          value: obj.value.split(','),
          addr: obj.addr,
          phone: obj.phone,
        })
      }
      // console.log(res1)
      this.option.series[0].data = res1
      this.ei.setOption(this.option)
      // this.ei.appendData({ seriesIndex: 0, data: res1 });
    }
  }

  async loadStadium2(params?: any) {
    const res1: any[] = []
    const res = await this.$store.dispatch(`datav/stadium2`, { ...params })
    // console.log(res)
    if (res && res.Data) {
      for (const obj of res.Data) {
        // obj.value += ",1"
        res1.push({
          id: obj.id,
          name: obj.name,
          value: obj.value.split(','),
          addr: obj.addr,
          phone: obj.phone,
        })
      }
      // console.log(res1)
      this.option.series[1].data = res1
      this.ei.setOption(this.option)
      // this.ei.appendData({ seriesIndex: 1, data: res1 });
    }
  }

  async loadStadium3(params?: any) {
    const res1: any[] = []
    const res = await this.$store.dispatch(`datav/stadium3`, { ...params })
    // console.log(res)
    if (res && res.Data) {
      for (const obj of res.Data) {
        // obj.value += ",1"
        if (obj.value) {
          res1.push({
            id: obj.id,
            name: obj.name,
            value: obj.value.split(','),
            addr: obj.addr,
            phone: obj.phone,
          })
        }
      }
      // console.log(res1)
      this.option.series[2].data = res1
      this.ei.setOption(this.option)
      // this.ei.appendData({ seriesIndex: 2, data: res1 });
    }
  }

  async loadStadium4(params?: any) {
    const res1: any[] = []
    const res = await this.$store.dispatch(`datav/stadium4`, { ...params })
    // console.log(res)
    if (res && res.Data) {
      for (const obj of res.Data) {
        // obj.value += ",1"
        res1.push({
          id: obj.id,
          name: obj.name,
          value: obj.value.split(','),
          addr: obj.addr,
          phone: obj.phone,
        })
      }
      // console.log(res1)
      this.option.series[3].data = res1
      // console.log(this.option)
      this.ei.setOption(this.option)
      // this.ei.appendData({ seriesIndex: 3, data: res1 });
      // console.log(this.ei.getOption())

      // @ts-ignore
      this.autotip = tools.loopShowTooltip(this.ei, this.option, {
        loopSeries: true,
        seriesIndex: 0,
        interval: 5000,
      })
    }
  }

  async loadStadium5(params?: any) {
    const res1: any[] = []
    const res = await this.$store.dispatch(`datav/stadium5`, { ...params })
    // console.log(res)
    if (res && res.Data) {
      for (const obj of res.Data) {
        // obj.value += ",1"
        res1.push({
          id: obj.id,
          name: obj.name,
          value: obj.value.split(','),
          addr: obj.addr,
          phone: obj.phone,
        })
      }
      // console.log(res1)
      this.option.series[5].data = res1
      this.ei.setOption(this.option)
      // this.ei.appendData({ seriesIndex: 5, data: res1 });
    }
  }

  async loadStadium6(params?: any) {
    const res1: any[] = []
    const res = await this.$store.dispatch(`datav/stadium6`, { ...params })
    // console.log(res)
    if (res && res.Data) {
      for (const obj of res.Data) {
        // obj.value += ",1"
        res1.push({
          id: obj.id,
          name: obj.name,
          value: obj.value.split(','),
          addr: obj.addr,
          phone: obj.phone,
        })
      }
      // console.log(res1)
      this.option.series[6].data = res1
      this.ei.setOption(this.option)
      // this.ei.appendData({ seriesIndex: 6, data: res1 });
    }
  }

  async loadBill() {
    // this.option.series[4].data = []
    const latestBillId = window.localStorage.getItem('latestBillId')
    const timecount: any = window.localStorage.getItem('timecount')
    // console.log(typeof latestBillId)
    const res1: any[] = []
    const res = await this.$store.dispatch(`datav/bill`)
    if (res && res.Data) {
      // 获取到的数据跟localstorage中保存的上次最大的id
      const latestBillId1: number = latestBillId
        ? parseInt(latestBillId, 10)
        : 0
      // console.log(latestBillId1)
      let j: number = 0
      let latestBillId2: any = 0
      for (const obj of res.Data) {
        if (parseInt(obj.id, 10) > latestBillId1) {
          if (j === 0) {
            // 管理后台是倒序的，所以第一个就是本次最大的id，保存起来
            // window.localStorage.setItem('latestBillId', obj.id)
            latestBillId2 = parseInt(obj.id, 10)
          }
          res1.push({
            name: obj.name,
            value: obj.value.split(','),
            stadium_name: obj.stadium_name,
            id: obj.id,
            addr: '',
            phone: obj.phone,
          })
          j++
        }
      }
      // console.log(res1)
      if (res1.length > 0) {
        this.option.series[4].data = _.concat(this.option.series[4].data, res1)
        // 60秒后，从序列中移除
        const myTimer = new Timer({
          tick: 1,
          onend: () => {
            this.option.series[4].data = _.drop(
              this.option.series[4].data,
              res1.length
            )
            this.ei.setOption(this.option)
          },
        })
        myTimer.start(60)
        this.ei.setOption(this.option)
        window.localStorage.setItem('latestBillId', latestBillId2)
      } else {
        // 新订单60秒后关闭
        // if (parseInt(timecount, 10) <= 10) {
        //   window.localStorage.setItem('timecount', timecount + 1)
        // } else {
        //   this.option.series[4].data = []
        //   this.ei.setOption(this.option)
        // }
        // window.setTimeout(() => {
        //   console.log('2222')
        //   this.option.series[4].data = []
        //   this.ei.setOption(this.option)
        // }, 60000)
      }
    }
  }

  loadStadiumData(params?: any) {
    this.loadStadium4(params)
    this.loadStadium1(params)
    this.loadStadium2(params)
    this.loadStadium3(params)
    this.loadStadium5(params)
    this.loadStadium6(params)
  }

  async mounted() {
    // console.log(this.$refs.ei)
    const chart: any = this.$refs.ei
    this.ei = chart.chart
    // @ts-ignore
    const timer2 = moment.duration(1, 'seconds').timer({ loop: true }, () => {
      this.curtime = moment().format('YYYY年MM月DD日 HH:mm:ss')
    })
    await this.country()

    this.loadStadiumData()
    // 场馆数据30分钟刷新一次
    let myFn1: any
    this.t1 = window.setTimeout(
      (myFn1 = () => {
        this.loadStadiumData()
        window.clearTimeout(this.t1)
        this.t1 = window.setTimeout(myFn1, 60000 * 30)
      }),
      60000 * 30
    )

    this.ei.on('mousemove', (params: any) => {
      // console.log('mouseover')
      // 鼠标移动的时候，移除新订单提示
      if (this.option.series[4].data.length > 0) {
        this.option.series[4].data = []
        this.ei.setOption(this.option)
      }
    })
    this.ei.on('click', (params: any) => {
      // console.log(params)
      if (params.componentType === 'series') {
        if (params.seriesIndex === 0) {
          this.showStadium(params.data.id)
        }
      }
      if (params.componentType === 'geo') {
        this.locationPos = params.name
      }
    })

    // 新订单1秒请求一次
    // this.loadBill()
    // let myFn2: any
    // this.t2 = window.setTimeout(
    //   (myFn2 = async () => {
    //     if (this.t2) {
    //       window.clearTimeout(this.t2)
    //     }
    //     const curhour = moment().hour()
    //     if (!(1 <= curhour && curhour <= 5)) {
    //       // 凌晨1点到5点之间不要实时刷新数据
    //       // await this.loadBill();
    //       this.t2 = window.setTimeout(myFn2, 2000)
    //     }
    //   }),
    //   2000
    // )
  }

  get computedLatestBillList() {
    return this.$store.state.latestBills
  }

  beforeUnmount() {
    clearTimeout(this.t1)
    clearTimeout(this.t2)
    this.autotip && this.autotip.clearLoop()
  }
  beforeDestroy() {
    clearTimeout(this.t1)
    clearTimeout(this.t2)
    this.autotip && this.autotip.clearLoop()
  }
}
