





import { Vue, Component, Mixins, Prop } from "vue-property-decorator";
import _ from "lodash";
import VueEcharts from "vue-echarts-ts";
import Echarts from "echarts";
import "echarts-gl";
Vue.use(VueEcharts);

@Component
export default class extends Mixins() {
  @Prop() userId!: number;
  @Prop() hideDetail!: (...args: any[]) => any;
  ei: any = {};

  categories: any[] = [
    { name: "球友", itemStyle: { color: "red" } },
    { name: "通讯录", itemStyle: { color: "white" } },
    { name: "球友的球友", itemStyle: { color: "blue" } }
  ];

  option: any = {
    title: {
      text: "",
      textStyle: { color: "#fff" },
      subtext: "",
      top: "top",
      left: "left"
    },
    tooltip: {},
    legend: [
      {
        textStyle: { color: "#fff" },
        // selectedMode: 'single',
        data: this.categories.map((a: any) => {
          return a.name;
        })
      }
    ],
    // animationDuration: 1500,
    // animationEasingUpdate: "quinticInOut",
    graphic: [
      {
        type: "text",
        z: 100,
        style: {
          fill: "#fff",
          text: ["球友关系"]
        },
        onclick: () => this.hideDetail()
      }
    ],
    animation: false,
    series: [
      {
        name: "球友关系",
        type: "graph",
        layout: "force",
        data: [],
        links: [],
        categories: this.categories,
        roam: true,
        animation: false,
        focusNodeAdjacency: true,
        force: {
          repulsion: 200
        },
        itemStyle: {
          borderColor: "#fff",
          borderWidth: 1,
          shadowBlur: 10,
          shadowColor: "rgba(0, 0, 0, 0.3)"
        },
        label: {
          position: "right"
        },
        lineStyle: {
          color: "source",
          curveness: 0.3,
          opacity: 0.1
        },
        emphasis: {
          lineStyle: {
            width: 10
          }
        }
      }
    ]
  };

  categoryName(obj: any) {
    switch (obj.type) {
      case "球友":
        return 0;
      case "通讯录":
        return 1;
      case "球友的球友":
        return 2;
    }
  }

  async mounted() {
    this.ei.showLoading("default", {
      text: "加载中，请稍后"
    });
    // this.ei.setOption(this.option);
    const res = await this.$store.dispatch(`datav/singlePlayer`, {
      user_id: this.userId
    });
    if (res && res.Data) {
      const nodes: any[] = [];
      const edges: any[] = [];
      for (const obj of res.Data) {
        const idx: number = _.findIndex(nodes, (o: any) => {
          return o.name === obj.id;
        });
        if (idx === -1) {
          nodes.push({
            // x: Math.random() * window.innerWidth,
            // y: Math.random() * window.innerHeight,

            name: obj.id,
            category: this.categoryName(obj)
            // fixed: true
          });
        }

        if (obj.source) {
          const idx1: number = _.findIndex(edges, (o: any) => {
            return o.source === obj.source && o.target === obj.target;
          });
          if (idx1 === -1) {
            edges.push({
              source: obj.source,
              target: obj.target
            });
          }
        }
      }
      this.option.series[0].data = nodes;
      this.option.series[0].links = edges;
      this.ei.setOption(this.option);
      this.ei.on("click", (params: any) => {
        console.log(params);
      });
      this.ei.on("mouseover", (params: any) => {
        // console.log(params);
        if (params.dataType === "edge") {
          this.ei.dispatchAction({
            type: "unfocusNodeAdjacency",
            seriesIndex: 0
          });
        }
      });
    }
    this.ei.hideLoading();
  }
}
