





import { Vue, Component, Mixins } from "vue-property-decorator";
import * as Moment from "moment";
import { extendMoment } from "moment-range";
import Echarts from "echarts";
import VueEcharts from "vue-echarts-ts";
Vue.use(VueEcharts);

const moment = extendMoment(Moment);

function getVirtulData(year: any) {
  year = year || "2017";

  const range = moment.range(
    new Date(2016, 0, 1, 0, 0, 0),
    new Date(2017, 0, 1, 0, 0, 0)
  );
  const data1: any = [];

  for (const month of range.by("days")) {
    data1.push([month.format("YYYY-MM-DD"), Math.floor(Math.random() * 10000)]);
  }

  return data1;
}

const data: any = getVirtulData("2016");
// console.log(data);

@Component
export default class extends Mixins() {
  ei: any = {};

  option: any = {
    // backgroundColor: "#404a59",

    // title: {
    //   top: 30,
    //   text: "2016年某人每天的步数",
    //   subtext: "数据纯属虚构",
    //   left: "center",
    //   textStyle: {
    //     color: "#fff"
    //   }
    // },
    tooltip: {
      trigger: "item"
    },
    legend: {
      top: "30",
      left: "100",
      data: ["步数", "Top 12"],
      textStyle: {
        color: "#fff"
      }
    },
    calendar: [
      {
        top: 100,
        cellSize: 15,
        left: "center",
        range: ["2016-01-01", "2016-06-30"],
        splitLine: {
          show: true,
          lineStyle: {
            color: "#000",
            width: 4,
            type: "solid"
          }
        },
        yearLabel: {
          formatter: "{start}  1st",
          textStyle: {
            color: "#fff"
          }
        },
        itemStyle: {
          color: "#323c48",
          borderWidth: 1,
          borderColor: "#111"
        }
      },
      {
        top: 240,
        cellSize: 15,
        left: "center",
        range: ["2016-07-01", "2016-12-31"],
        splitLine: {
          show: true,
          lineStyle: {
            color: "#000",
            width: 4,
            type: "solid"
          }
        },
        yearLabel: {
          formatter: "{start}  2nd",
          textStyle: {
            color: "#fff"
          }
        },
        itemStyle: {
          color: "#323c48",
          borderWidth: 1,
          borderColor: "#111"
        }
      }
    ],
    series: [
      {
        name: "步数",
        type: "scatter",
        coordinateSystem: "calendar",
        data,
        symbolSize: (val: any) => {
          return val[1] / 500;
        },
        itemStyle: {
          color: "#ddb926"
        }
      },
      {
        name: "步数",
        type: "scatter",
        coordinateSystem: "calendar",
        calendarIndex: 1,
        data,
        symbolSize: (val: any) => {
          return val[1] / 500;
        },
        itemStyle: {
          color: "#ddb926"
        }
      },
      {
        name: "Top 12",
        type: "effectScatter",
        coordinateSystem: "calendar",
        calendarIndex: 1,
        data: data
          .sort((a: any, b: any) => {
            return b[1] - a[1];
          })
          .slice(0, 12),
        symbolSize: (val: any) => {
          return val[1] / 500;
        },
        showEffectOn: "render",
        rippleEffect: {
          brushType: "stroke"
        },
        hoverAnimation: true,
        itemStyle: {
          color: "#f4e925",
          shadowBlur: 10,
          shadowColor: "#333"
        },
        zlevel: 1
      },
      {
        name: "Top 12",
        type: "effectScatter",
        coordinateSystem: "calendar",
        data: data
          .sort((a: any, b: any) => {
            return b[1] - a[1];
          })
          .slice(0, 12),
        symbolSize: (val: any) => {
          return val[1] / 500;
        },
        showEffectOn: "render",
        rippleEffect: {
          brushType: "stroke"
        },
        hoverAnimation: true,
        itemStyle: {
          color: "#f4e925",
          shadowBlur: 10,
          shadowColor: "#333"
        },
        zlevel: 1
      }
    ]
  };

  mounted() {
    this.ei.showLoading("default", {
      text: "加载中，请稍后"
    });
    this.ei.setOption(this.option);
    this.ei.hideLoading();
  }
}
