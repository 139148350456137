
























































































































































































import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'

import _ from 'lodash'

// import MyDialog from '@/components/dialog.vue'
import XEUtils from 'xe-utils'
import { Modal, VXETable } from 'vxe-table'
import 'vxe-table/lib/style.css'
import zhCN from 'vxe-table/lib/locale/lang/zh-CN'

import MyList from '@/mixins/list'

import YearPicker from '@/components/YearPicker.vue'

// @ts-ignore
import MyDvPage from '$ui/dv/packages/my-dv-page'

// @ts-ignore
import MyDvLoading from '$ui/dv/packages/my-dv-loading'

// @ts-ignore
import MyDvBorder6 from '$ui/dv/packages/my-dv-border6'
// @ts-ignore
import MyDvTitle from '$ui/dv/packages/my-dv-title'
// @ts-ignore
import MyDvText from '$ui/dv/packages/my-dv-text'
// @ts-ignore
import MyDvBox from '$ui/dv/packages/my-dv-box'
// @ts-ignore
import MyDvPie from '$ui/dv/packages/my-dv-pie'

VXETable.setup({
  i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(zhCN, key), args),
})

Vue.prototype.$XModal = VXETable.modal

Vue.use(Modal)

import BudgetModal from './budget.vue'

@Component({
  components: {
    MyDvPage,
    MyDvLoading,
    MyDvBorder6,
    MyDvTitle,
    MyDvText,
    // MyDialog,
    MyDvBox,
    MyDvPie,
    YearPicker,
    BudgetModal,
  },
})
export default class extends Mixins(MyList) {
  @Prop() title!: string

  @Prop() stadiumId!: number

  @Prop() pageVisible!: boolean

  budgetModalVisible: boolean = false

  loading1: boolean = false

  columns1 = ['类型', '数值']

  rows1 = [
    ['房租水电', 0],
    ['人力成本', 0],
    ['利润', 0],
    ['其它', 0],
  ]

  extend1 = {
    series: {
      label: {
        // position: 'inner',
        formatter: '{c}元',
      },
      //   stillShowZeroSum: false,
    },
  }

  type1 = 'month'

  year1 = '2021-11'

  compare1 = 'before'

  columns2 = ['类型', '数值']

  rows2 = [
    ['营业剩余而空置', 43],
    ['营业售出', 3],
    ['不营业而空置', 60],
  ]

  extend2 = {
    series: {
      label: {
        position: 'inner',
        formatter: '{c}小时',
      },
    },
  }

  type2 = 'month'

  year2 = ''

  compare2 = 'before'

  columns3 = ['类型', '数值']

  rows3 = [
    ['会员卡利润', 43500],
    ['会员卡优惠', 3000],
  ]

  extend3 = {
    series: {
      label: {
        position: 'inner',
      },
    },
  }

  type3 = 'month'

  year3 = ''

  compare3 = 'before'

  columns4 = ['类型', '数值']

  rows4 = [
    ['每年营业', 350],
    ['每年不营业', 15],
  ]

  extend4 = {
    series: {
      label: {
        position: 'inner',
        formatter: '{c}天',
      },
    },
  }

  columns5 = ['类型', '数值']

  rows5 = [
    ['每天营业', 11],
    ['每天不营业', 13],
  ]

  extend5 = {
    series: {
      label: {
        position: 'inner',
        formatter: '{c}小时',
      },
    },
  }

  columns6 = ['类型', '数值']

  rows6 = [
    ['实际卖出', 5],
    ['浪费', 6],
  ]

  extend6 = {
    series: {
      label: {
        position: 'inner',
        formatter: '{c}小时',
      },
    },
  }

  showBudgetModal() {
    this.budgetModalVisible = true
  }

  hideBudgetModal() {
    this.budgetModalVisible = false
  }

  modalShow() {
    // @ts-ignore
    this.$refs.modal1.maximize()
  }

  show1() {
    this.loading1 = true

    this.loadChart1()

    this.loadChart2()

    this.loadChart4()

    this.loadChart5()

    this.loadChart6()
  }

  async loadChart1() {
    const res = await this.$store.dispatch('datav/taisamData', {
      stadium_id: this.stadiumId,
      data_type: 'chart1',
      month: this.year1,
    })
    if (res && res.Data) {
      this.rows1 = [
        ['房租', res.Data.money1],
        ['水电', res.Data.money2],
        ['人力成本', res.Data.money3],
        ['利润', res.Data.money5],
        ['其它', res.Data.money4],
      ]
    }
  }

  async loadChart2() {
    const res = await this.$store.dispatch('datav/taisamData', {
      stadium_id: this.stadiumId,
      data_type: 'chart2',
      month: this.year1,
    })
    if (res && res.Data) {
      this.rows2 = [
        ['营业剩余而空置', res.Data.un_sale_hour],
        ['营业售出', res.Data.sale_hour],
        ['不营业而空置', res.Data.total_hour1],
      ]
    }
  }

  async loadChart3() {
    const res = await this.$store.dispatch('datav/taisamData', {
      stadium_id: this.stadiumId,
      data_type: 'chart3',
      month: this.year1,
    })
    if (res && res.Data) {
    }
  }

  async loadChart4() {
    const res = await this.$store.dispatch('datav/taisamData', {
      stadium_id: this.stadiumId,
      data_type: 'chart4',
      month: this.year1,
    })
    if (res && res.Data) {
      this.rows4 = [
        ['每年营业', res.Data.festival_count1],
        ['每年不营业', res.Data.festival_count],
      ]
    }
  }

  async loadChart5() {
    const res = await this.$store.dispatch('datav/taisamData', {
      stadium_id: this.stadiumId,
      data_type: 'chart5',
      month: this.year1,
    })
    if (res && res.Data) {
      this.rows5 = [
        ['每天营业', res.Data.business_time1_count],
        ['每天不营业', 24 - res.Data.business_time1_count],
      ]
    }
  }

  async loadChart6() {
    const res = await this.$store.dispatch('datav/taisamData', {
      stadium_id: this.stadiumId,
      data_type: 'chart6',
      month: this.year1,
    })
    if (res && res.Data) {
      this.rows6 = [
        ['实际卖出', res.Data.sale_hour],
        ['浪费', 13 - res.Data.sale_hour],
      ]
    }
  }

  async loadChart7() {
    const res = await this.$store.dispatch('datav/taisamData', {
      stadium_id: this.stadiumId,
      data_type: 'chart7',
      month: this.year1,
    })
    if (res && res.Data) {
    }
  }

  mounted() {
    window.setTimeout(() => {
      this.show1()
    }, 1000)
  }

  handleChartClick() {}

  yearChange(e: any) {
    console.log(e)
  }

  radioChange1(e: any) {
    console.log(e)
    if (e.target.value == 'after') {
      this.$set(this.rows1, 1, ['人力成本', 0])
    } else {
      this.$set(this.rows1, 1, ['人力成本', 3000])
    }
  }

  radioChange2(e: any) {
    console.log(e)

    if (e.target.value == 'after') {
      this.$set(this.rows4, 0, ['每年营业', 365])
      this.$set(this.rows4, 1, ['每年不营业', 0])

      this.$set(this.rows5, 0, ['每天营业', 24])
      this.$set(this.rows5, 1, ['每天不营业', 0])
    } else {
      this.$set(this.rows4, 0, ['每年营业', 350])
      this.$set(this.rows4, 1, ['每年不营业', 15])

      this.$set(this.rows5, 0, ['每天营业', 11])
      this.$set(this.rows5, 1, ['每天不营业', 13])
    }
  }

  radioChange3(e: any) {
    console.log(e)
  }
}
