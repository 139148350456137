











































import { Vue, Component, Mixins } from 'vue-property-decorator'
import moment from 'moment'
import { Carousel } from 'ant-design-vue'

import jq from 'jquery'

// @ts-ignore
import vueSeamless from '@/components/vue-seamless-scroll/components/myClass.vue'

import _ from 'lodash'

Vue.use(Carousel)

@Component({
  components: {
    vueSeamless,
  },
})
export default class extends Mixins() {
  classOption: any = {
    waitTime: 0,
    isSingleRemUnit: true,
    step: 1.2,
  }

  translate: string = ''
  top: number = 0
  msgList: any[] = []

  rowsNum: number = 6
  lineHeight: number = 3.9583

  data: any[] = []
  tempData: any[] = [] // 临时维护重复的行为

  t1: any = null
  t2: any = null
  t3: any = null

  box1: any = null
  box2: any = null
  boxwrap: any = null
  boxtop: number = 0

  renderUsername(mobile: string) {
    return `用户${mobile.substring(7)}`
  }

  roll(t: number) {
    this.box1 = this.$refs.box1
    this.box2 = this.$refs.box2
    this.boxwrap = this.$refs.boxwrap
    this.box2.innerHTML = this.box1.innerHTML
    this.boxtop = 0
    let myFn3: any
    // this.t2 = window.setInterval(this.rollStart, t)
    this.t2 = window.setTimeout(
      (myFn3 = () => {
        this.rollStart()
        window.clearTimeout(this.t2)
        this.t2 = window.setTimeout(myFn3, t)
      }),
      t
    )
    this.boxwrap.onmouseover = () => {
      // window.clearInterval(this.t2)
      window.clearTimeout(this.t2)
    }
    this.boxwrap.onmouseout = () => {
      // this.t2 = setInterval(this.rollStart, t)
      this.t2 = window.setTimeout(
        (myFn3 = () => {
          this.rollStart()
          window.clearTimeout(this.t2)
          this.t2 = window.setTimeout(myFn3, t)
        }),
        t
      )
    }
  }

  rollStart() {
    if (this.boxtop >= this.box1.scrollHeight) {
      this.boxtop = 0
    } else {
      this.boxtop += 4
    }
  }

  addData(params: any) {
    const obj: any = jq('.timelineItem')
    let obj1: any = null
    const boxwrap: any = jq('.boxwrap:first')
    const divtop = boxwrap.height() + 300
    // for (let i = 0; i < obj.length; i++) {
    for (const obj3 of obj) {
      const obj2: any = jq(obj3)

      if (obj2.offset().top - divtop < 0) {
        obj1 = obj3
      }
    }
    $(obj1).after(`<div class="timelineItem">
              <div class="user">
                <div class="userInner">${this.renderUsername(
                  params.mobile
                )}</div>
              </div>
              <div class="activity">${this.renderPath(params.path)}</div>
              <div class="activitytime">
              <div class="atdate">${this.renderTimeDate(
                params.created_time
              )}</div>
              <div class="attime">${this.renderTTime(params.created_time)}</div>
            </div>
              <div class="cf"></div>
            </div>`)
  }

  renderTimeDate(t: string) {
    return moment(t).format('MM-DD')
  }

  renderTTime(t: string) {
    return moment(t).format('HH:mm:ss')
  }

  async mounted() {
    this.loadData1().then(() => {
      this.$nextTick(() => {
        this.roll(50)
      })
    })

    // 动态8分钟刷新一次

    let myFn1: any
    this.t1 = window.setTimeout(
      (myFn1 = () => {
        const curhour = moment().hour()
        if (!(1 <= curhour && curhour <= 5)) {
          // 凌晨1点到5点之间不要实时刷新数据
          this.loadData1()
        }
        window.clearTimeout(this.t1)
        this.t1 = window.setTimeout(myFn1, 60000 * 8)
      }),
      60000 * 8
    )

    let myFn2: any
    this.t3 = window.setTimeout(
      (myFn2 = () => {
        const curhour = moment().hour()
        if (!(1 <= curhour && curhour <= 5)) {
          // 凌晨1点到5点之间不要实时刷新数据
          // this.loadData2();
        }
        window.clearTimeout(this.t3)
        this.t3 = window.setTimeout(myFn2, 5000)
      }),
      5000
    )
  }

  beforeUnmount() {
    window.clearTimeout(this.t1)
    window.clearTimeout(this.t2)
    window.clearTimeout(this.t3)
  }
  beforeDestroy() {
    window.clearTimeout(this.t1)
    window.clearTimeout(this.t2)
    window.clearTimeout(this.t3)
  }

  async loadData1() {
    const res = await this.$store.dispatch('datav/activityLog1')
    if (res && res.Data && res.Data !== '{}') {
      this.tempData = this.data = res.Data
      if (res.Data.length > 0) {
        // 结果的第一个 元素
        const firstElem: any = _.last(res.Data)
        // console.log(firstElem)
        // 保存

        window.localStorage.setItem('latestActivityId', firstElem.id)
      }
    }
  }

  async loadData2() {
    // 记录下本次最大的id
    const latestActivityId = window.localStorage.getItem('latestActivityId')
    // 获取到的数据跟localstorage中保存的上次最大的id
    const latestActivityId1: number = latestActivityId
      ? parseInt(latestActivityId, 10)
      : 0
    // 每5秒查一次有没有最新的，有最新的接入到前边数据的恰当位置上
    const res = await this.$store.dispatch('datav/activityLog2', {
      latest_max_id: latestActivityId1,
    })
    if (res && res.Data && res.Data !== '{}') {
      if (res.Data.length > 0) {
        // 结果的第一个 元素
        const firstElem: any = _.last(res.Data)

        // 保存
        window.localStorage.setItem('latestActivityId', firstElem.id)
      }

      for (const obj of res.Data) {
        // 已经在列表中的不显示出来

        const idx = _.findIndex(this.tempData, (o: any) => {
          return o.username === obj.username && o.path === obj.path
        })
        if (idx !== -1) {
          // 已经有了，先删除
          _.remove(this.data, (o: any) => {
            return o.username === obj.username && o.path === obj.path
          })
        }
        // 插入新的
        this.tempData.push(obj)

        // 插入新的
        // if (!res) {
        this.addData({
          username: obj.username,
          mobile: obj.mobile,
          path: obj.path,
        })
        // }
      }
    }
  }

  renderPath(path: string) {
    return path.substring(path.lastIndexOf('|') + 1)
  }
}
