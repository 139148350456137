






















import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'

import MyList from '@/mixins/list'

// @ts-ignore
import MyDvPage from '$ui/dv/packages/my-dv-page'

// @ts-ignore
import MyDvBox from '$ui/dv/packages/my-dv-box'

import XEUtils from 'xe-utils'
import { Modal, VXETable } from 'vxe-table'
import 'vxe-table/lib/style.css'
import zhCN from 'vxe-table/lib/locale/lang/zh-CN'

import Detail from './g6detail.vue'

VXETable.setup({
  i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(zhCN, key), args),
})

Vue.prototype.$XModal = VXETable.modal

Vue.use(Modal)

@Component({
  components: {
    MyDvPage,
    MyDvBox,
    Detail,
  },
})
export default class extends Mixins(MyList) {
  @Prop() pageVisible!: boolean

  @Prop() userId!: number
  @Prop() userName!: string

  modalShow() {
    // @ts-ignore
    this.$refs.modal1.maximize()
  }
}
