import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

import Home from '@/views/home.vue'
import Stadium from '@/views/stadium260.vue'
import Player from '@/views/player.vue'
import PlayerGraph from '@/views/player/ecgraph.vue'
import PlayerDetail from '@/views/player/detail.vue'
import EchartsComp from '@/views/echarts.vue'
import Login from '@/views/login.vue'

const originalPush = Router.prototype.push
// @ts-ignore
Router.prototype.push = function push(location) {
  // @ts-ignore
  return originalPush.call(this, location).catch((err: any) => err)
}

Vue.use(Router)

// 页面刷新时，重新赋值token
if (window.sessionStorage.getItem('token')) {
  store.commit('user/saveToken', window.sessionStorage.getItem('token'))
}

// @ts-ignore
const router = new Router({
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
      beforeEnter: (to: any, from: any, next: any) => {
        const { query } = to
        if (query.token) {
          // 快速登录
          const token = query.token.substring(0, query.token.length - 7)
          window.sessionStorage.setItem('token', token)
          window.location.href = '/'
        }
        next()
      },
    },
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/v1/main.vue'),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          path: '',
          component: Home,
          meta: {
            requireAuth: true,
          },
        },
        {
          path: 'stadium',
          name: 'Stadium',
          component: Stadium,
          meta: {
            requireAuth: true,
          },
        },
        {
          path: 'player',
          component: Player,
          meta: {
            requireAuth: true,
          },
        },
        {
          path: 'player-graph',
          component: PlayerGraph,
          meta: {
            requireAuth: true,
          },
        },
        {
          path: 'player-detail',
          component: PlayerDetail,
          meta: {
            requireAuth: true,
          },
        },
        {
          path: 'echarts',
          component: EchartsComp,
          meta: {
            requireAuth: true,
          },
        },
      ],
    },
    {
      path: '/v2',
      component: () => import('@/views/v2/index.vue'),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          path: 'index',
          component: () => import('@/views/v2/overview.vue'),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: 'stadium',
          component: () => import('@/views/v2/stadium.vue'),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: 'stadium/detail',
          component: () => import('@/views/v2/stadium_detail.vue'),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: 'order',
          component: () => import('@/views/v2/order.vue'),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: 'user',
          component: () => import('@/views/v2/user.vue'),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: 'demo3',
          component: () => import('@/views/v2/demo3.vue'),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: 'demo4',
          component: () => import('@/views/v2/demo4.vue'),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: 'demo5',
          component: () => import('@/views/v2/demo5.vue'),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: 'demo6',
          component: () => import('@/views/v2/demo6.vue'),
          meta: {
            requireAuth: true,
          },
        },
      ],
    },
    {
      path: '/v3',
      component: () => import('@/views/v3/main.vue'),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          path: '',
          component: () => import('@/views/v3/index.vue'),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: 'user',
          component: () => import('@/views/v3/user.vue'),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: 'operation',
          component: () => import('@/views/v3/operation.vue'),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: 'order',
          component: () => import('@/views/v3/order.vue'),
          meta: {
            requireAuth: true,
          },
        },
      ],
    },
    {
      path: '/v4',
      component: () => import('@/views/v4/main.vue'),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          path: '',
          component: () => import('@/views/v4/index.vue'),
          meta: {
            requireAuth: true,
          },
        },
      ],
    },
    {
      path: '/v5',
      component: () => import('@/views/v5/main.vue'),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          path: '',
          component: () => import('@/views/v5/index.vue'),
          meta: {
            requireAuth: true,
          },
        },
      ],
    },
    {
      path: '/v6',
      component: () => import('@/views/v6/main.vue'),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          path: '',
          component: () => import('@/views/v6/index.vue'),
          meta: {
            requireAuth: true,
          },
        },
      ],
    },
  ],
})

router.beforeEach((to: any, from: any, next: any) => {
  Vue.prototype.$httpRequestList.forEach((cancel: any) => {
    cancel()
  })
  if (to.matched.some((r: any) => r.meta.redirect)) {
    router.push(to.meta.redirect)
    return
  }
  // console.log(next)
  // console.log(from)
  if (window.location.host === '192.168.1.5:8020') {
    next()
    return
  }
  if (to.matched.some((r: any) => r.meta.requireAuth)) {
    if (store.state.user.token) {
      // 如果是登录过来的获取刷新页面的，获取下用户信息、用户菜单和用户权限
      if (from.path === '/' || from.path === '/login') {
        store.dispatch('user/getCurrentUserInfo', {}).then(() => {
          // console.log('获取下权限')
          next()
        })
      } else {
        next()
      }
    } else {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      })
    }
  } else {
    next()
  }
})

export default router
