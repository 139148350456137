import Vue from 'vue'
import Vuex from 'vuex'

import User from '@/store/module/user'
import Datav from '@/store/module/datav'
import Users from '@/store/module/users'
import Stadium from '@/store/module/stadium'
import Statistics from '@/store/module/statistics'
import Bill from '@/store/module/bill'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    breadcrumbs: [],
    latestBills: [],
  },
  mutations: {
    setLatestBills(state, payload) {
      state.latestBills = payload
    },
  },
  actions: {
    flushLocalUserInfo({ commit }) {
      window.sessionStorage.removeItem('token')
      commit('user/removeToken')
    },
    async refreshToken({ commit, dispatch }, params) {
      return dispatch('user/refreshToken')
    },
    setLatestBills({ commit }, params) {
      // console.log(params)
      commit('setLatestBills', params)
    },
  },
  modules: {
    user: User,
    datav: Datav,
    users: Users,
    stadium: Stadium,
    statistics: Statistics,
    bill: Bill,
  },
})
