





















































































































import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'
import VueEcharts from 'vue-echarts-ts'

require('@/utils/echarts-auto-tooltip')

import config from '@/config'

import VModal from 'vue-js-modal'

// @ts-ignore
import vueSeamless from '@/components/vue-seamless-scroll/components/myClass.vue'

import Scroll from './stadium_scroll.vue'

Vue.use(VModal)

Vue.use(VueEcharts)

@Component({
  components: {
    vueSeamless,
    Scroll,
  },
})
export default class extends Mixins() {
  classOption: any = {
    step: 1,
    limitMoveNum: 1,
    waitTime: 1000,
    singleHeight: 60,
  }

  @Prop() showStadium!: (...args: any[]) => any

  t3!: any

  stadiums: any[] = []

  ai: any = {
    ccount1: 0,
    ccount2: 0,
    ccount3: 0,
    ccount4: 0,
    ccount5: 0,
  }

  ei: any = {}

  option: any = {
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)',
    },
    series: [
      {
        name: '智能场馆',
        type: 'pie',
        radius: ['0', '100%'],
        // center: ['50%', '50%'],
        // avoidLabelOverlap: false,
        hoverAnimation: false,
        label: {
          normal: {
            show: false,
            position: 'center',
          },
        },
        labelLine: {
          normal: {
            // show: false
          },
        },
        data: [
          {
            value: this.ai.ccount2,
            name: '已运营',
            itemStyle: { color: 'rgba(243, 234, 85, 0.95)' },
          },
          {
            value: this.ai.ccount3,
            name: '待升级',
            itemStyle: { color: 'rgba(194, 204, 43, 0.95)' },
          },
          {
            value: this.ai.ccount4,
            name: '升级中',
            itemStyle: { color: 'rgba(251, 171, 56, 0.95)' },
          },
          {
            value: this.ai.ccount5,
            name: '待跟进',
            itemStyle: { color: 'rgba(96,187,231, 0.95)' },
          },
        ],
      },
    ],
  }

  // 智能场馆
  async loadAi() {
    const res = await this.$store.dispatch(`datav/ai`)
    // console.log(res)
    if (res && res.Data) {
      this.ai = res.Data
      this.option.series[0].data[0].value = this.ai.ccount2
      this.option.series[0].data[1].value = this.ai.ccount3
      this.option.series[0].data[2].value = this.ai.ccount4
      this.option.series[0].data[3].value = this.ai.ccount5
      // this.ei.setOption(this.option)
      // @ts-ignore
      // tools.loopShowTooltip(this.ei, this.option, { loopSeries: true, interval: 5000 })
      // this.ei.resize()
    }
  }

  showAiStadium() {
    // console.log(this.$store.state.user.token)
    const d = window.dialog({
      title: `场馆运营`,
      url: `${config.serverapi}datav/aistadium?stadium_type=type3&host=${window.location.host}&authorization=${this.$store.state.user.token}`,
      width: 900,
      height: 600,
      zIndex: 2147483647,
    })
    // console.log(d)
    d.show()
  }

  async loadStadium1() {
    const res = await this.$store.dispatch(`datav/stadium7`, {})
    if (res && res.Data) {
      this.stadiums = res.Data
    }
  }

  showAiStadium1() {
    this.$modal.show('hello-world')
  }

  mounted() {
    this.loadAi()
    // 智能场馆数据1分钟刷一次

    let myFn1: any
    this.t3 = window.setTimeout(
      (myFn1 = () => {
        const curhour = moment().hour()
        if (!(1 <= curhour && curhour <= 5)) {
          // 凌晨1点到5点之间不要实时刷新数据
          this.loadAi()
          this.loadStadium1()
        }
        window.clearTimeout(this.t3)
        this.t3 = window.setTimeout(myFn1, 60000 * 1)
      }),
      60000 * 1
    )

    this.loadStadium1()
  }

  beforeUnmount() {
    window.clearTimeout(this.t3)
  }

  beforeDestroy() {
    window.clearTimeout(this.t3)
  }
}
