import { Vue, Component, Mixins } from 'vue-property-decorator'
import qs from 'qs'
import moment from 'moment'
import _ from 'lodash'

import MyCommon from './common'

@Component
export default class MyList extends Mixins(MyCommon) {
  changeRoute = true

  dispatchType!: string

  dispatchTypeDelete!: string

  deleteConfirmTitle = '确认信息'

  deleteConfirmContent = '确定吗？'

  // 搜索条件
  defaultData: { [t: string]: any } = {}

  searchData: { [t: string]: any } = {}

  reloadQueryData: { [t: string]: any } = {}
  // createdAt1: any = null
  // createdAt2: any = null

  data: any[] = []

  pagination: any = {
    // position: '',
    // showSizeChanger: true,
    // showQuickJumper: true,
    // pageSizeOptions: ['10', '100', '1000', '5000', '10000'],
    size: 'small',
    showTotal: (total: number) => `总计 ${total} 条记录`,
  }

  addModalVisible = false

  editModalVisible = false

  editId = 0

  mounted() {
    // console.log('mounted2')
    const query = { ...this.$route.query }

    delete query.page
    delete query.page_size
    // if (query.created_at1) {
    //     this.createdAt1 = moment(query.created_at1 as string)
    // }
    // if (query.created_at2) {
    //     this.createdAt2 = moment(query.created_at2 as string)
    // }
    // console.log(this.changeRoute)

    const obj: any = {}

    for (const key in query) {
      if (key) {
        _.set(obj, key.replace('[]', ''), _.get(query, [key]))
      }
    }

    this.searchData = { ...obj }
    // console.log('2')
    // this.loadData()
  }

  handleGlobalTableChange(page: number, pageSize: number) {
    const pager = { ...this.$store.state.gPagination }
    // console.log(pager)
    if (_.has(pager, ['dispatchType'])) {
      this.dispatchType = _.get(pager, ['dispatchType'])
    }
    pager.current = page
    pager.pageSize = pageSize
    // console.log(pager)
    this.pagination = pager
    this.loadData(
      {
        ...this.reloadQueryData,
        page,
        page_size: pageSize,
      },
      this.changeRoute
    )
  }

  handleTableChange(pagination: any, filters: any, sorter: any) {
    // console.log(pagination)
    if (!_.isEmpty(sorter)) {
      this.searchData.sorter_field = sorter.field
      this.searchData.sorter_order = sorter.order
    }
    const pager = { ...this.pagination }
    pager.current = pagination.current
    pager.pageSize = pagination.pageSize
    // console.log(pager)
    this.pagination = pager
    this.loadData(
      {
        ...this.reloadQueryData,
        page: pagination.current,
        page_size: pagination.pageSize,
      },
      this.changeRoute
    )
  }

  handleTableSearch(e: any) {
    e.preventDefault()
    this.form.validateFields((err: any, fieldsValue: any) => {
      if (!err) {
        // console.log(fieldsValue)
        const values: any = {}
        for (const key in fieldsValue) {
          if (_.get(fieldsValue, [key])) {
            const fieldValue = _.get(fieldsValue, [key])
            if (moment.isMoment(fieldValue)) {
              _.set(
                values,
                [key],
                moment(fieldValue).format('YYYY-MM-DD HH:mm:ss')
              )
            } else if (key === 'area') {
              _.set(values, ['province_id'], _.get(fieldValue, [0], ''))
              _.set(values, ['city_id'], _.get(fieldValue, [1], ''))
              _.set(values, ['county_id'], _.get(fieldValue, [2], ''))
            } else {
              _.set(values, [key], fieldValue)
            }
          } else {
            _.unset(this.reloadQueryData, [key])
          }
        }
        this.searchData = {
          ...this.defaultData,
          ...this.reloadQueryData,
          ...values,
        }
        this.loadData(
          {
            ...this.defaultData,
            page: 1,
          },
          this.changeRoute
        )
      }
    })
  }

  handleTableSearchReset() {
    this.searchData = {
      ...this.reloadQueryData,
    }
    this.loadData(
      {
        ...this.defaultData,
        page: 1,
      },
      this.changeRoute
    )
  }

  async loadData(params: any = {}, changeRoute = true) {
    this.defaultData = params

    let { page } = params
    let { page_size } = params

    if (changeRoute === false) {
      // 如果是弹窗的数据列表，则不从url的query获取数据
      const query = { ...this.$route.query }

      for (const k in query) {
        if (_.get(query, [k])) {
          delete this.searchData[k]
        }
      }
    }

    this.reloadQueryData = { ...params, ...this.searchData }
    // console.log(this.reloadQueryData)
    // console.log(changeRoute)
    this.changeRoute = changeRoute
    if (this.changeRoute === true) {
      const query = { ...this.$route.query }
      // console.log(query)
      // console.log(this.searchData)
      page = page || query.page
      page_size = page_size || query.page_size

      this.$router
        .push(
          `${this.$route.path}?${qs.stringify(
            {
              // ...query,
              ...this.searchData,
              page,
              page_size,
            },
            { arrayFormat: 'brackets' }
          )}`
        )
        .catch((err) => {
          // nothing to do
        })
    }
    // console.log(this.reloadQueryData)
    this.loading = true
    const res = await this.$store.dispatch(this.dispatchType, {
      ...this.reloadQueryData,
      page,
      page_size,
    })
    if (res && res.Data) {
      const pagination = { ...this.pagination }
      // console.log(res)
      if (res.Data !== '{}') {
        pagination.total = res.Data.total
        this.data = res.Data.data

        // console.log(pagination)
        pagination.pageSize = res.Data.pageSize
        pagination.current = res.Data.page
      }

      this.pagination = pagination

      if (this.changeRoute) {
        this.pagination.dispatchType = this.dispatchType
        // 如果不是弹窗列表，在页面底部显示分页
        this.$store.dispatch('setGPagination', this.pagination)
      }
    }
    this.loading = false
  }

  async reloadData(params: any = {}) {
    this.loading = true
    const pagination = { ...this.pagination }
    const res = await this.$store.dispatch(this.dispatchType, {
      page: params.page ? params.page : pagination.current,
      page_size: params.page_size ? params.page_size : pagination.pageSize,
      ...this.reloadQueryData,
    })

    pagination.total = res.Data.total
    this.data = res.Data.data
    this.loading = false
    // console.log(pagination)
    pagination.pageSize = res.Data.pageSize
    pagination.current = res.Data.page
    this.pagination = pagination
  }

  showAddModal(params?: any) {
    if (_.has(params, ['permKey'])) {
      if (!this.checkPermission(params.permKey)) {
        this.$message.error('没有权限')
        return
      }
    }
    this.addModalVisible = true
  }

  hideAddModal() {
    this.addModalVisible = false
    this.reloadData()
  }

  showEditModal(id: number, params?: any) {
    if (_.has(params, ['permKey'])) {
      if (!this.checkPermission(params.permKey)) {
        this.$message.error('没有权限')
        return
      }
    }
    this.editId = id
    this.editModalVisible = true
  }

  hideEditModal() {
    this.editModalVisible = false
    this.reloadData()
  }

  handleDelete(id: number, params?: any) {
    if (_.has(params, ['permKey'])) {
      if (!this.checkPermission(params.permKey)) {
        this.$message.error('没有权限')
        return
      }
    }
    this.showTsConfirm({
      title: this.deleteConfirmTitle,
      content: this.deleteConfirmContent,
      onOk: async () => {
        const res = await this.$store.dispatch(this.dispatchTypeDelete, {
          id,
          ...params,
        })
        if (res && res.Message === 'success') {
          this.$message.success(res.Message)
          this.reloadData()
        } else {
          this.$message.error(res.Message)
        }
      },
      onCancel() {
        // alert('cancel')
      },
    })
  }

  renderOrderInfo(row: any) {
    if (Number(row.type) === 9) {
      return '-'
    }

    let isMuniteBill = false

    if (moment(row.valid_time_start).diff(moment(row.created_time)) > 0) {
      // 如果打球开始时间在下单时间之后
    } else {
      // 否则是分钟单
      isMuniteBill = true
    }

    let minute = 0

    if (moment(row.created_time).diff(moment(row.valid_time_start)) > 0) {
      // 要加一分钟
      minute = 1
    }

    const cost_desc: any = JSON.parse(row.cost_desc)

    let weekstr = ''
    for (const week of this.weeks) {
      if (parseInt(week.key, 10) === parseInt(cost_desc[0].time_week, 10)) {
        weekstr = week.name
      }
    }
    if (isMuniteBill === true) {
      if (minute === 1) {
        return `${cost_desc[0].time_date_day} ${weekstr} ${moment(
          row.created_time
        )
          .add(minute, 'minutes')
          .format('HH:mm')}-${moment(row.valid_time_end).format('HH:mm')} ${
          row.ground_names
        } ${this.renderMoney(row.cost / 100)} 元 ${
          row.from_source === '6'
            ? `馆长：${this.renderMoney(row.cost1 / 100)}元`
            : ''
        }`
      }
      return `${cost_desc[0].time_date_day} ${weekstr} ${moment(
        row.valid_time_end
      )
        .subtract(Number(cost_desc[0].time_min) - minute, 'minutes')
        .format('HH:mm')}-${moment(row.valid_time_end).format('HH:mm')} ${
        row.ground_names
      } ${this.renderMoney(row.cost / 100)} 元 ${
        row.from_source === '6'
          ? `馆长：${this.renderMoney(row.cost1 / 100)}元`
          : ''
      }`
    }
    return `${cost_desc[0].time_date_day} ${weekstr} ${moment(
      row.valid_time_start
    ).format('HH:mm')}-${moment(row.valid_time_end).format('HH:mm')} ${
      row.ground_names
    } ${this.renderMoney(row.cost / 100)} 元 ${
      row.from_source === '6'
        ? `馆长：${this.renderMoney(row.cost1 / 100)}元`
        : ''
    } ${row.cost_type == '1' ? `${row.participation_num}人` : ''}`
  }
}
