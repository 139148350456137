import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

import api from '@/api/users'

@Module({ namespaced: true })
export default class extends VuexModule {
  @Action
  async list(params: any) {
    return api.list(params)
  }

  @Action
  async vipcard(params: any) {
    return api.vipcard(params)
  }
}
