import Vue from 'vue'
import axios from 'axios'
import _ from 'lodash'
import store from '@/store'
import router from '@/router'
import appconfig from '@/config'

const url = require('url')

const CANCEL_TOKEN = axios.CancelToken
const MAX_CANCEL_TOKEN_NUM = 2000

Vue.prototype.$httpRequestList = []

let isRefreshing: boolean = false

let retryRequests: any[] = []

axios.defaults.withCredentials = true

const instance = axios.create({
  baseURL: appconfig.serverapi,
  timeout: 120000,
  maxRedirects: 0,
  withCredentials: true,
  headers: {
    // 'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
    'Content-Type': 'application/x-www-form-urlencoded',
  },
})

// 无需做权限拦截的 uri 列表
const exceptApiUris: string[] = []

const redirectLogin = () => {
  Vue.prototype.$message.error('请登录', 3)
  store.dispatch('flushLocalUserInfo')
  // console.log(router.currentRoute.path)
  if (router.currentRoute.path !== '/login') {
    router.replace({
      path: '/login',
      query: { redirect: router.currentRoute.fullPath },
    })
  }
}

// 拦截所有的 api 请求，未来可做缓存/代理等
instance.interceptors.request.use(
  (config: any) => {
    config.headers.Authorization = `Bearer ${window.sessionStorage.getItem(
      'token'
    )}`

    const targetUrl = config.url
    const target = url.parse(targetUrl)

    config.CancelToken = new CANCEL_TOKEN((c) => {
      if (Vue.prototype.$httpRequestList.length == MAX_CANCEL_TOKEN_NUM) {
        Vue.prototype.$httpRequestList.pop()
      }
      Vue.prototype.$httpRequestList.unshift(c)
    })

    // TODO 发向站外的 ajax 请求暂时无法做权限拦截
    if (target.hostname !== location.hostname) {
      return config
    }

    const targetApiUri = target.pathname.substring(
      '/'.length,
      target.pathname.length
    )
    if (exceptApiUris.includes(targetApiUri)) {
      return config
    }

    /*
      let user_has_api = store.state.user.apis.some(use_api => user_api_uri === target_api_uri)
      if(!user_has_api){
          Message.info({
              content:'抱歉，你没有权限',
              duration:3
          })
          return Promise.reject('cancelled locally')
      } */
    // loading
    return config
  },
  (error) =>
    // console.log(error)
    Promise.reject(error)
)
// 拦截所有的 api 响应，此处会检查会话是否已过期
instance.interceptors.response.use(
  (response) => {
    const { config } = response

    if (response.headers['content-disposition'] === 'attachment') {
      return Promise.resolve(response)
    }

    const jsonResponse = response.data
    // console.log(jsonResponse)
    if (_.has(jsonResponse, 'Code')) {
      if (parseInt(jsonResponse.Code, 10) === 200) {
        return Promise.resolve(jsonResponse)
      }

      if (parseInt(jsonResponse.Code, 10) === 403) {
        // 需要通过refresh token获取新的access_token
        if (!isRefreshing) {
          isRefreshing = true
          return store
            .dispatch('refreshToken')
            .then((resp) => {
              if (resp && resp.Data) {
                window.sessionStorage.setItem('token', resp.Data)
                retryRequests.forEach((cb) => cb())

                retryRequests = []
                config.transformRequest = [(data) => data]
                instance(config)
              } else {
                // 需要去登录页面
                redirectLogin()
              }
            })
            .catch(() => {
              // 需要去登录页面
              redirectLogin()
            })
            .finally(() => {
              isRefreshing = false
            })
        } else {
          return new Promise((resolve, reject) => {
            // 重试队列
            retryRequests.push(() => {
              resolve(instance(config))
            })
          })
        }
      }

      if (parseInt(jsonResponse.Code, 10) === 401) {
        // 需要去登录页面
        redirectLogin()

        return Promise.resolve(jsonResponse)
      }
    }
    // console.log('2')

    return Promise.resolve(jsonResponse)
  },
  (error) => {
    if (error === 'cancelled locally') {
      return Promise.reject(error)
    }
    // console.log(error)
    if (error.response.status === 429) {
      Vue.prototype.$message.error('你的请求频率太快啦', 3)
      return Promise.reject(error)
    }

    Vue.prototype.$message.error('网络异常，请稍后再试！', 3)
    return Promise.reject(error)
  }
)

export default instance
