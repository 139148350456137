import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueFullscreen from 'vue-fullscreen'
import VueAnimXYZ from '@animxyz/vue'
import '@animxyz/core'
import AmapVue from '@amap/amap-vue'

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import 'animate.css'

Vue.use(ElementUI)
Vue.use(VueFullscreen)

// import AmapVueConfig from '@amap/amap-vue/lib/config'
AmapVue.config.version = '2.0'
AmapVue.config.key = '7824a85bace28f4562fa331ee78811be'
Vue.use(AmapVue)

Vue.config.productionTip = false

Vue.use(VueAxios, axios)

Vue.use(Antd)

Vue.use(VueAnimXYZ)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
