import axios from '@/request';
import axiosRetry from 'axios-retry'
import qs from 'qs'

axiosRetry(axios, { retries: 3 });

export default {
    async logincaptcha(params: any) {
        return axios.get('cap/digitC', { params })
    },
    async getUser(params: any) {
        return axios.get('admin/info', { params })
    },
    async login(params: any) {
        return axios.post('admin/login', qs.stringify(params))
    },
    async refreshToken(params: any) {
        return axios.get('admin/refresh-token', { params })
    }
}
