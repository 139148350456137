




















import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'

@Component
export default class extends Mixins() {
  @Prop() camera!: any

  renderImage(img: string) {
    if (!img) {
      img = '/assets/cameras/3.jpg'
    }

    return `https://data.taisam.cn${img}`
  }
}
