











































































import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'
import _ from 'lodash'
import G6 from '@antv/g6'

import icon from '@/assets/icon'
// @ts-ignore
import insertCss from 'insert-css'
insertCss(`
  .g6-tooltip {
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    font-size: 12px;
    color: #000;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 10px 8px;
    box-shadow: rgb(174, 174, 174) 0px 0px 10px;
  }
`)
@Component
export default class extends Mixins() {
  @Prop() userId!: number
  @Prop() userName!: string
  @Prop() hideDetail!: (...args: any[]) => any

  num1: number = 0
  num2: number = 0
  num3: number = 0
  num4: number = 0

  loading: boolean = false

  roboticon: string = icon.robot.replace('image://', '')

  hideDetail1() {
    this.hideDetail()
  }

  categoryName(obj: any) {
    switch (obj.type) {
      case '球友':
        return 'red'
      case '社群球友':
        return 'blue'
      case '球友的球友':
        return 'yellow'
      case '球友的社群球友':
        return 'green'
      default:
        return '#ffffff'
    }
  }

  renderUsername(mobile: string) {
    return `用户${mobile.substring(7)}`
  }

  async mounted() {
    setTimeout(() => {
      this.loadData()
    }, 500)
  }

  async loadData() {
    const width: number = Number(
      // @ts-ignore
      window.getComputedStyle(this.$refs.mountNode).width.replace('px', '')
    )

    const height: number = Number(
      // @ts-ignore
      window.getComputedStyle(this.$refs.mountNode).height.replace('px', '')
    )

    const graph = new G6.Graph({
      // @ts-ignore
      container: this.$refs.mountNode,
      width,
      height,
      modes: {
        default: [
          'zoom-canvas',
          {
            type: 'tooltip',
            formatText: (model: any) => {
              if (model.type1 === '') {
                return `用户：${this.renderUsername(model.mobile)}(${
                  model.mobile
                })`
              }
              return `${this.userName}的${
                model.type1
              }<br />${this.renderUsername(model.mobile)}(${model.mobile})`
            },
          },
          'drag-canvas',
          'drag-node',
          'activate-relations',
        ],
      },
      // fitView: true,
      // fitViewPadding: 50,
      layout: {
        type: 'force',
        linkDistance: (d: any) => {
          // console.log(d);
          if (d.target.type1 === '球友') {
            return 100
          } else if (d.target.type1 === '球友的球友') {
            return 100
          } else if (d.target.type1 === '社群球友') {
            return 200
          }

          return 200
        },
        // preventOverlap: true
        // maxLevelDiff: 0.5,
        // sortBy: "pos",
        // workerEnabled: true
      },
      // animate: true,
      // groupByTypes: false,
      defaultNode: {
        size: 5,
        style: {
          fill: '#C6E5FF',
        },
      },
      defaultEdge: {
        type: 'arc',
        size: 1,
        color: '#999',
        style: {
          opacity: 0.2,
        },
        labelCfg: {
          autoRotate: true,
          refY: -10,
        },
      },
      nodeStateStyles: {
        active: {
          opacity: 1,
        },
        inactive: {
          opacity: 0.2,
        },
      },
      edgeStateStyles: {
        active: {
          opacity: 1,
        },
        inactive: {
          opacity: 0.2,
        },
      },
    })

    this.loading = true

    const res1 = await this.$store.dispatch(`datav/singlePlayerData`, {
      user_id: this.userId,
    })
    if (res1 && res1.Data) {
      this.num1 = res1.Data.ccount1
      this.num2 = res1.Data.ccount2
      this.num3 = res1.Data.ccount3
      this.num4 = res1.Data.ccount4
    }

    const res = await this.$store.dispatch(`datav/singlePlayer`, {
      user_id: this.userId,
    })
    if (res && res.Data) {
      const nodes: any[] = []
      const edges: any[] = []

      const qiuyou: string[] = []

      const tempArr: string[] = []

      for (const obj of res.Data) {
        if (obj.type === '球友') {
          // this.num1++;
          qiuyou.push(obj.mobile)
        } else if (obj.type === '社群球友') {
          // this.num2++;
        } else if (obj.type === '球友的球友') {
          // this.num3++;
        }
        if (
          _.findIndex(nodes, (o: any) => {
            return o.mobile === obj.mobile
          }) === -1
        ) {
          nodes.push({
            id: obj.mobile,
            // x: 500,
            // y: 500,
            // x: Math.random() * window.innerWidth,
            // y: Math.random() * window.innerHeight,
            username: this.renderUsername(obj.mobile),
            mobile: obj.mobile,
            label: obj.type === '' ? this.renderUsername(obj.mobile) : '',
            type1: obj.type,
            //   type: "image",
            //   img: `https://data.taisam.cn${obj.avatar}`,
            //   type: obj.type,
            //   comboId: obj.type,
            size: obj.type === '' ? 30 : obj.type === '球友' ? 20 : 10,
            // pos: obj.type === "" ? 10000 : Number(obj.id) % 100,
            style: {
              fill: this.categoryName(obj),
            },
          })
        }

        if (obj.source && obj.target) {
          if (
            _.indexOf(tempArr, `edge${obj.target}-${obj.source}`) === -1 &&
            _.indexOf(tempArr, `edge${obj.source}-${obj.target}`) === -1
          ) {
            tempArr.push(`edge${obj.source}-${obj.target}`)

            const edge: any = {
              id: `edge${obj.source}-${obj.target}`,
              source: obj.source,
              target: obj.target,
              curveOffset: 20,
            }

            if (
              _.indexOf(qiuyou, obj.source) !== -1 &&
              _.indexOf(qiuyou, obj.target) !== -1
            ) {
              // 都是球友
              edge.style = {
                stroke: 'red',
                opacity: 0.3,
              }
            }

            edges.push(edge)
          }
        }
      }

      // console.log(
      //   JSON.stringify({
      //     nodes,
      //     edges
      //   })
      // );

      graph.data({
        nodes,
        edges,
      })
      graph.render()
    }

    this.loading = false

    // graph.positionsAnimate();
  }
}
