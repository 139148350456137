import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

import api from '@/api/user'

@Module({ namespaced: true })
export default class extends VuexModule {
    token: string = ''

    userInfo: any = {}

    @Action
    async getCurrentUserInfo(params: any) {
        // 当前登录用户信息
        const res: any = await api.getUser(params)
        // console.log(res)
        if (res && res.Data) {
            this.context.commit('setUserInfo', res.Data.admin_user)
        }

    }

    @Action
    async logincaptcha(params: any) {
        return api.logincaptcha(params)
    }

    @Action
    async login(params: any) {
        const res: any = await api.login(params)
        // console.log(res)
        if (res && res.Message === 'success') {
            // console.log('login success')
            // console.log(res.Data.token)

            window.sessionStorage.setItem('token', res.Data.token)
            this.context.commit('saveToken', res.Data.token)
            // this.context.commit('setUserInfo', res.data)
        }
        return res
    }

    @Action
    async refreshToken(params: any) {
        return api.refreshToken(params)
    }

    @Mutation
    saveToken(token: string) {
        this.token = token
    }

    @Mutation
    removeToken() {
        this.token = ''
        window.sessionStorage.removeItem('token')
    }

    @Mutation
    setUserInfo(info: any) {
        this.userInfo = info
    }
}
