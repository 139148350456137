import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import api from '@/api/statistics'

@Module({ namespaced: true })
export default class extends VuexModule {
  @Action
  async lineChart(params: any) {
    return api.lineChart(params)
  }

  @Action
  async getSummary(params: any) {
    return api.getSummary(params)
  }

  @Action
  async getOrders(params: any) {
    return api.getOrders(params)
  }

  @Action
  async getUsers(params: any) {
    return api.getUsers(params)
  }

  @Action
  async userTotal(params: any) {
    return api.userTotal(params)
  }

  @Action
  async getStadiums(params: any) {
    return api.getStadiums(params)
  }

  @Action
  async stadiumTotal(params: any) {
    return api.stadiumTotal(params)
  }

  @Action
  async getPayments(params: any) {
    return api.getPayments(params)
  }

  @Action
  async getWholeStadiumMap(params: any) {
    return api.getWholeStadiumMap(params)
  }

  @Action
  async getStadiumMap(params: any) {
    return api.getStadiumMap(params)
  }

  @Action
  async getHourTrend(params: any) {
    return api.getHourTrend(params)
  }

  @Action
  async getHourRank(params: any) {
    return api.getHourRank(params)
  }

  @Action
  async getBillSettleType(params: any) {
    return api.getBillSettleType(params)
  }

  @Action
  async getBillType(params: any) {
    return api.getBillType(params)
  }

  @Action
  async getDownload(params: any) {
    return api.getDownload(params)
  }

  @Action
  async latestUsers(params: any) {
    return api.latestUsers(params)
  }

  @Action
  async latestWorkorder(params: any) {
    return api.latestWorkorder(params)
  }

  @Action
  async latestEnterapply(params: any) {
    return api.latestEnterapply(params)
  }

  @Action
  async latestUpgrade(params: any) {
    return api.latestUpgrade(params)
  }

  @Action
  async stadiumPieData(params: any) {
    return api.stadiumPieData(params)
  }

  @Action
  async stadiumUserC(params: any) {
    return api.stadiumUserC(params)
  }

  @Action
  async userBillCount(params: any) {
    return api.userBillCount(params)
  }

  @Action
  async userPlayCount(params: any) {
    return api.userPlayCount(params)
  }

  @Action
  async stadiumBill(params: any) {
    return api.stadiumBill(params)
  }

  @Action
  async stadiumGround(params: any) {
    return api.stadiumGround(params)
  }

  @Action
  async stadiumGroundUsed(params: any) {
    return api.stadiumGroundUsed(params)
  }

  @Action
  async stadiumGroundUsedPrediction(params: any) {
    return api.stadiumGroundUsedPrediction(params)
  }

  @Action
  async billShareRankByUser(params: any) {
    return api.billShareRankByUser(params)
  }

  @Action
  async billShareRankByBill(params: any) {
    return api.billShareRankByBill(params)
  }

  @Action
  async userPlayerCountRank(params: any) {
    return api.userPlayerCountRank(params)
  }

  @Action
  async userCreditRank(params: any) {
    return api.userCreditRank(params)
  }

  @Action
  async dailyActiveUser(params: any) {
    return api.dailyActiveUser(params)
  }

  @Action
  async downloadPieData(params: any) {
    return api.downloadPieData(params)
  }

  @Action
  async beforeDay(params: any) {
    return api.beforeDay(params)
  }

  @Action
  async userActivity(params: any) {
    return api.userActivity(params)
  }

  @Action
  async userActivityBill(params: any) {
    return api.userActivityBill(params)
  }

  @Action
  async userActivityPlay(params: any) {
    return api.userActivityPlay(params)
  }

  @Action
  async downloadHour24Data(params: any) {
    return api.downloadHour24Data(params)
  }

  @Action
  async downloadTrendData(params: any) {
    return api.downloadTrendData(params)
  }
}
