




















import { Vue, Component, Mixins, Prop } from "vue-property-decorator";
import jq from "jquery";
@Component
export default class extends Mixins() {
  @Prop() stadiums!: any[];
  @Prop() showAiStadium1!: (...args: any[]) => any;

  t1: any = null;
  t2: any = null;
  t3: any = null;

  box1: any = null;
  box2: any = null;
  boxwrap: any = null;
  boxtop: number = 0;

  roll(t: number) {
    this.box1 = this.$refs.box11;
    this.box2 = this.$refs.box21;
    this.boxwrap = this.$refs.boxwrap1;
    this.box2.innerHTML = this.box1.innerHTML;
    this.boxtop = 0;
    let myFn3: any;
    // this.t2 = window.setInterval(this.rollStart, t)
    this.t2 = window.setTimeout(
      (myFn3 = () => {
        this.rollStart();
        window.clearTimeout(this.t2);
        this.t2 = window.setTimeout(myFn3, t);
      }),
      t
    );
    this.boxwrap.onmouseover = () => {
      // window.clearInterval(this.t2)
      window.clearTimeout(this.t2);
    };
    this.boxwrap.onmouseout = () => {
      // this.t2 = setInterval(this.rollStart, t)
      this.t2 = window.setTimeout(
        (myFn3 = () => {
          this.rollStart();
          window.clearTimeout(this.t2);
          this.t2 = window.setTimeout(myFn3, t);
        }),
        t
      );
    };
  }

  rollStart() {
    const obj: any = jq(".stadiumItem");
    if (this.boxtop >= this.box1.scrollHeight) {
      this.boxtop = 0;
    } else {
      this.boxtop += obj.height();
    }
    this.$nextTick(() => {
      //   console.log(this.boxtop);
      jq(".boxwrap1").animate({ marginTop: -this.boxtop });
    });
  }

  mounted() {
    this.roll(2000);
  }

  beforeDestroy() {
    // window.clearTimeout(this.t2);
  }
}
