












import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'
@Component
export default class extends Mixins() {
  @Prop() value!: string

  value1: string = ''

  open: boolean = false

  @Watch('value')
  watchValue(newVal: any, oldVal: any) {
    console.log(newVal)
    console.log(oldVal)
  }

  openChange(status: any) {
    // console.log(status)
    this.open = status
  }

  panelChange(value: any, mode: string) {
    // console.log(value)
    // console.log(mode)

    this.open = !open

    // console.log(value.year())

    this.value1 = String(value.year())

    // console.log(this.value1)

    this.$emit('change', String(value.year()))
  }

  mounted() {
    if (this.value) {
      this.value1 = this.value
    }
  }
}
