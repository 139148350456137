import axios from '@/request'
import axiosRetry from 'axios-retry'
import qs from 'qs'

axiosRetry(axios, { retries: 3 })

export default {
  async total(params: any) {
    return axios.get('datav/total', { params })
  },
  async maptotal(params: any) {
    return axios.get('datav/map-total', { params })
  },
  async ai(params: any) {
    return axios.get('datav/ai', { params })
  },
  async activityLog(params: any) {
    return axios.get('datav/activity-log', { params })
  },
  async activityLog1(params: any) {
    return axios.get('datav/activity-log1', { params })
  },
  async activityLog2(params: any) {
    return axios.get('datav/activity-log2', { params })
  },
  async map(params: any) {
    return axios.get('datav/map', { params })
  },
  async stadium1(params: any) {
    return axios.get('datav/stadium1', { params })
  },
  async stadium2(params: any) {
    return axios.get('datav/stadium2', { params })
  },
  async stadium3(params: any) {
    return axios.get('datav/stadium3', { params })
  },
  async stadium4(params: any) {
    return axios.get('datav/stadium4', { params })
  },
  async stadium5(params: any) {
    return axios.get('datav/stadium5', { params })
  },
  async stadium6(params: any) {
    return axios.get('datav/stadium6', { params })
  },
  async stadium7(params: any) {
    return axios.get('datav/stadium7', { params })
  },
  async bill(params: any) {
    return axios.get('datav/bill', { params })
  },
  async stadiumDetail(params: any) {
    return axios.get('datav/stadium-detail', { params })
  },
  async stadiumTotal(params: any) {
    return axios.get('datav/stadium-total', { params })
  },
  async stadiumAI(params: any) {
    return axios.get('datav/stadium-ai', { params })
  },
  async stadiumAI2(params: any) {
    return axios.get('datav/stadium-ai2', { params })
  },
  async stadiumCamera(params: any) {
    return axios.get('datav/stadium-camera', { params })
  },
  async graph(params: any) {
    return axios.get('datav/graph', { params })
  },
  async userCount(params: any) {
    return axios.get('datav/user-count', { params })
  },
  async userGraph(params: any) {
    return axios.get('datav/user-graph', { params })
  },
  async playrecordgraph(params: any) {
    return axios.get('datav/playrecord-graph', { params })
  },
  async userData(params: any) {
    return axios.get('datav/user-data', { params })
  },
  async singlePlayer(params: any) {
    return axios.get('datav/single-player', { params })
  },
  async singlePlayerData(params: any) {
    return axios.get('datav/single-player-data', { params })
  },
  async userMap(params: any) {
    return axios.get('datav/user-map', { params })
  },
  async userPointMap(params: any) {
    return axios.get('datav/user-point-map', { params })
  },
  async getHourTrend(params: any) {
    return axios.get('statistics/hour-trend', { params })
  },
  async playerRank(params: any) {
    return axios.get('datav/player-rank', { params })
  },
  async contactRank(params: any) {
    return axios.get('datav/contact-rank', { params })
  },
  async orderRank(params: any) {
    return axios.get('datav/order-rank', { params })
  },
  async playRank(params: any) {
    return axios.get('datav/play-rank', { params })
  },
  async userType(params: any) {
    return axios.get('datav/user-type', { params })
  },
  async billType(params: any) {
    return axios.get('datav/bill-type', { params })
  },
  async orderSummary(params: any) {
    return axios.get('datav/order-summary', { params })
  },
  async todayBuy(params: any) {
    return axios.get('datav/today-buy', { params })
  },
  async todayPlay(params: any) {
    return axios.get('datav/today-play', { params })
  },
  async todayBillList(params: any) {
    return axios.get('datav/today-bill-list', { params })
  },
  async userSummary(params: any) {
    return axios.get('datav/user-summary', { params })
  },
  async areaUserCount(params: any) {
    return axios.get('datav/area-user-count', { params })
  },
  async taisamData(params: any) {
    return axios.get('datav/taisam-data', { params })
  },
  async aistadiumdata(params: any) {
    return axios.get('datav/aistadiumdata', { params })
  },
}
