import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import api from '@/api/bill'

@Module({ namespaced: true })
export default class extends VuexModule {
  @Action
  async getBills(params: any) {
    return api.getBills(params)
  }

  @Action
  async detail(params: any) {
    return api.detail(params)
  }
}
