import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

import api from '@/api/datav'

@Module({ namespaced: true })
export default class extends VuexModule {
  @Action
  async total(params: any) {
    return api.total(params)
  }

  @Action
  async maptotal(params: any) {
    return api.maptotal(params)
  }

  @Action
  async ai(params: any) {
    return api.ai(params)
  }

  @Action
  async activityLog(params: any) {
    return api.activityLog(params)
  }

  @Action
  async activityLog1(params: any) {
    return api.activityLog1(params)
  }

  @Action
  async activityLog2(params: any) {
    return api.activityLog2(params)
  }

  @Action
  async map(params: any) {
    return api.map(params)
  }

  @Action
  async stadium1(params: any) {
    return api.stadium1(params)
  }

  @Action
  async stadium2(params: any) {
    return api.stadium2(params)
  }

  @Action
  async stadium3(params: any) {
    return api.stadium3(params)
  }

  @Action
  async stadium4(params: any) {
    return api.stadium4(params)
  }

  @Action
  async stadium5(params: any) {
    return api.stadium5(params)
  }

  @Action
  async stadium6(params: any) {
    return api.stadium6(params)
  }

  @Action
  async stadium7(params: any) {
    return api.stadium7(params)
  }

  @Action
  async bill(params: any) {
    return api.bill(params)
  }

  @Action
  async stadiumDetail(params: any) {
    return api.stadiumDetail(params)
  }

  @Action
  async stadiumTotal(params: any) {
    return api.stadiumTotal(params)
  }

  @Action
  async stadiumAI(params: any) {
    return api.stadiumAI(params)
  }

  @Action
  async stadiumAI2(params: any) {
    return api.stadiumAI2(params)
  }

  @Action
  async stadiumCamera(params: any) {
    return api.stadiumCamera(params)
  }

  @Action
  async graph(params: any) {
    return api.graph(params)
  }

  @Action
  async userCount(params: any) {
    return api.userCount(params)
  }

  @Action
  async userGraph(params: any) {
    return api.userGraph(params)
  }

  @Action
  async playrecordgraph(params: any) {
    return api.playrecordgraph(params)
  }

  @Action
  async userData(params: any) {
    return api.userData(params)
  }

  @Action
  async singlePlayer(params: any) {
    return api.singlePlayer(params)
  }

  @Action
  async singlePlayerData(params: any) {
    return api.singlePlayerData(params)
  }

  @Action
  async userMap(params: any) {
    return api.userMap(params)
  }

  @Action
  async userPointMap(params: any) {
    return api.userPointMap(params)
  }

  @Action
  async getHourTrend(params: any) {
    return api.getHourTrend(params)
  }

  @Action
  async playerRank(params: any) {
    return api.playerRank(params)
  }

  @Action
  async contactRank(params: any) {
    return api.contactRank(params)
  }

  @Action
  async orderRank(params: any) {
    return api.orderRank(params)
  }

  @Action
  async playRank(params: any) {
    return api.playRank(params)
  }

  @Action
  async userType(params: any) {
    return api.userType(params)
  }

  @Action
  async billType(params: any) {
    return api.billType(params)
  }

  @Action
  async orderSummary(params: any) {
    return api.orderSummary(params)
  }

  @Action
  async todayBuy(params: any) {
    return api.todayBuy(params)
  }

  @Action
  async todayPlay(params: any) {
    return api.todayPlay(params)
  }

  @Action
  async todayBillList(params: any) {
    return api.todayBillList(params)
  }

  @Action
  async userSummary(params: any) {
    return api.userSummary(params)
  }

  @Action
  async areaUserCount(params: any) {
    return api.areaUserCount(params)
  }

  @Action
  async taisamData(params: any) {
    return api.taisamData(params)
  }

  @Action
  async aistadiumdata(params: any) {
    return api.aistadiumdata(params)
  }
}
