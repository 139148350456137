





















































import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'
import _ from 'lodash'
import moment from 'moment'
import MyList from '@/mixins/list'

// @ts-ignore
import MyDvList from '@xdh/my/ui/lib/dv/packages/my-dv-list'

// @ts-ignore
import MyDvLoading from '@xdh/my/ui/lib/dv/packages/my-dv-loading'

@Component({
  components: {
    MyDvList,
    MyDvLoading,
  },
})
export default class extends Mixins(MyList) {
  @Prop() params?: any

  dispatchType: string = 'users/list'

  columns: any[] = [
    { title: '头像', scopedSlots: { customRender: 'avatar' } },
    { title: '用户名', dataIndex: 'username' },
    { title: '手机号', scopedSlots: { customRender: 'mobile' } },
    { title: '注册时间', dataIndex: 'created_time' },
    { title: '最新下单时间', dataIndex: 'latest_order_time' },
    { title: '最新打球时间', dataIndex: 'latest_play_time' },
  ]

  async mounted() {
    this.loadData(
      {
        mode: 'simple',
        ...this.params,
      },
      false
    )
  }
}
