import axios from '@/request'
import qs from 'qs'
import _ from 'lodash'

const config = {
  common: 'tsdata-1257401801.cos.ap-shanghai.myqcloud.com',
  secret: 'securedata-1257401801.cos.ap-shanghai.myqcloud.com',
}

export default config

// 创建上传路径
export function buildUrl(isSecret = false, path = '', query = '') {
  return `https://${isSecret ? config.secret : config.common}${path}${
    query ? `?${query}` : ''
  }`
}

export const buildSecretUrl = (path = '', query = '') =>
  buildUrl(true, path, query)

export const buildCommonurl = (path = '', query = '') =>
  buildUrl(false, path, query)

// 获取token
export const fetchCosAuthToken = (
  path = '',
  type = 'face',
  method = 'put',
  contentType = 'image/jpeg',
  ext = 'jpg'
) =>
  axios.get('cos/auth', {
    params: {
      path,
      type,
      method,
      contentType,
      ext,
    },
  })

// 私密图片
export const getSecretImageCosAuthToken = (path = '') =>
  fetchCosAuthToken(path, 'face', 'get')
// 私密视频
export const getSecretVideoCosAuthToken = (path = '') =>
  fetchCosAuthToken(path, 'face', 'get', 'audio/mp4', 'mp4')

export const getSecretImage = async (path = '') => {
  try {
    // const { Data: { token } } = await getSecretImageCosAuthToken(path)
    const res = await getSecretVideoCosAuthToken(path)
    // console.log(res)
    if (res && _.get(res, 'Data')) {
      const token = _.get(res, ['Data', 'token'])
      return buildSecretUrl(path, token)
    }
  } catch (e) {
    return Promise.reject(e)
  }
}

export const getSecretVideo = async (path = '') => {
  try {
    const res = await getSecretVideoCosAuthToken(path)
    if (res && _.has(res, 'Data')) {
      const token = _.get(res, ['Data', 'token'])
      return buildSecretUrl(path, token)
    }
  } catch (e) {
    return Promise.reject(e)
  }
}
