








































































import { Vue, Component, Mixins } from 'vue-property-decorator'
import _ from 'lodash'
import VueEcharts from 'vue-echarts-ts'
Vue.use(VueEcharts)
import icon from '@/assets/icon'

import Calendar1 from './player/calendar.vue'
import Radar1 from './player/radar.vue'
@Component({
  components: {
    Calendar1,
    Radar1,
  },
})
export default class extends Mixins() {
  ei: any = {}

  option: any = {
    title: {
      text: '',
      textStyle: {
        color: '#ffffff',
      },
    },
    tooltip: {
      formatter: (x: any) => {
        return x.data.username
      },
    },
    series: [
      {
        name: '球友',
        type: 'graph',
        layout: 'none',
        data: [],
        edges: [],
        links: [],
        roam: true,
        animation: false,
        focusNodeAdjacency: false,
        force: {
          //   repulsion: 1000
        },
        lineStyle: {
          color: 'source',
          opacity: 0.05,
          curveness: 0.3,
        },
        emphasis: {
          lineStyle: {
            width: 1,
            opacity: 1,
          },
        },
        label: {
          emphasis: {
            show: true,
            position: 'right',
            formatter: (x: any) => {
              if (x.data.username === '小羽') {
                return '小羽'
              }
              return `${x.data.username}`
            },
            color: '#ffffff',
          },
        },
      },
    ],
  }

  async mounted() {
    this.ei.showLoading('default', {
      text: '加载中，请稍后',
    })

    this.option.series[0].data = []
    this.option.series[0].edges = []

    const res = await this.$store.dispatch('datav/graph', {})
    if (res && res.Data) {
      //   console.log(res.Data.nodes);
      //   for (const node of res.Data.nodes) {
      //     this.option.series[0].data.push({
      //       name: node.name
      //     });
      //   }
      this.option.series[0].nodes = res.Data.nodes.map((node: any) => {
        // node.symbolSize = Number(node.ucount) / 1.5;
        // node.fixed = true;
        node.x = Math.random() * window.innerWidth
        node.y = Math.random() * window.innerHeight
        node.label = {
          normal: {
            show: node.ucount > 30,
            position: 'right',
            color: '#fff',
            formatter: (x: any) => {
              return x.data.username
            },
          },
        }
        node.symbol =
          node.label === '小羽'
            ? icon.robot
            : Number(node.sex) === 2
            ? icon.female
            : icon.male
        node.itemStyle = {
          color:
            node.label === '小羽'
              ? '#ffffff'
              : Number(node.sex) === 2
              ? '#eb2f96'
              : '#1890ff',
        }
        return Object.assign({}, node)
      })
      this.option.series[0].data = this.option.series[0].nodes

      //   this.option.series[0].edges = res.Data.edges.map((edge: any) => {
      //     return Object.assign({}, edge);
      //   });
      const tempArr: string[] = []
      for (const obj of res.Data.edges) {
        if (
          _.indexOf(tempArr, `${obj.target}-${obj.source}`) === -1 &&
          _.indexOf(tempArr, `${obj.source}-${obj.target}`) === -1
        ) {
          tempArr.push(`${obj.source}-${obj.target}`)
          this.option.series[0].edges.push(obj)
        }
      }

      this.option.series[0].links = this.option.series[0].edges

      //   this.option.series[0].links = res.Data.edges;
      console.log(this.option)
      this.ei.on('click', (params: any) => {
        console.log(params)
        // 显示球友详情页

        // if (params.dataType === "node") {
        //   this.ei.dispatchAction({
        //     type: "focusNodeAdjacency",
        //     seriesIndex: 0,
        //     dataIndex: params.dataIndex
        //   });
        // } else {
        //   this.ei.dispatchAction({
        //     type: "unfocusNodeAdjacency",
        //     seriesIndex: 0
        //   });
        // }
      })
      this.ei.setOption(this.option)
      this.ei.hideLoading()
    }
  }
}
