import axios from '@/request'
import qs from 'qs'

export default {
  async lineChart(params: any) {
    return axios.get('statistics/line-chart', { params })
  },
  async getSummary(params: any) {
    return axios.get('statistics/index', { params })
  },
  async getOrders(params: any) {
    return axios.get('statistics/orders', { params })
  },
  async getUsers(params: any) {
    return axios.get('statistics/users', { params })
  },
  async userTotal(params: any) {
    return axios.get('statistics/user-total', { params })
  },
  async getStadiums(params: any) {
    return axios.get('statistics/stadiums', { params })
  },
  async stadiumTotal(params: any) {
    return axios.get('statistics/stadium-total', { params })
  },
  async getPayments(params: any) {
    return axios.get('statistics/payments', { params })
  },
  async getWholeStadiumMap(params: any) {
    return axios.get('statistics/wholestadiummap', { params })
  },
  async getStadiumMap(params: any) {
    return axios.get('statistics/stadiummap', { params })
  },
  async getHourTrend(params: any) {
    return axios.get('statistics/hour-trend', { params })
  },
  async getHourRank(params: any) {
    return axios.get('statistics/hour-rank', { params })
  },
  async getBillSettleType(params: any) {
    return axios.get('statistics/bill-settle-type', { params })
  },
  async getBillType(params: any) {
    return axios.get('statistics/bill-type', { params })
  },
  async getDownload(params: any) {
    return axios.get('statistics/download', { params })
  },
  async latestUsers(params: any) {
    return axios.get('statistics/latest-users', { params })
  },
  async latestWorkorder(params: any) {
    return axios.get('statistics/latest-workorder', { params })
  },
  async latestEnterapply(params: any) {
    return axios.get('statistics/latest-enterapply', { params })
  },
  async latestUpgrade(params: any) {
    return axios.get('statistics/latest-upgrade', { params })
  },
  async stadiumPieData(params: any) {
    return axios.get('statistics/stadium-pie-data', { params })
  },
  async stadiumUserC(params: any) {
    return axios.get('statistics/stadium-user-c', { params })
  },
  async userBillCount(params: any) {
    return axios.get('statistics/user-bill-count', { params })
  },
  async userPlayCount(params: any) {
    return axios.get('statistics/user-play-count', { params })
  },
  async stadiumBill(params: any) {
    return axios.get('statistics/stadium-bill', { params })
  },
  async stadiumGround(params: any) {
    return axios.get('statistics/stadium-ground', { params })
  },
  async stadiumGroundUsed(params: any) {
    return axios.get('statistics/stadium-ground-used', { params })
  },
  async stadiumGroundUsedPrediction(params: any) {
    return axios.get('statistics/stadium-ground-used-prediction', { params })
  },
  async billShareRankByUser(params: any) {
    return axios.get('statistics/bill-share-rank-by-user', { params })
  },
  async billShareRankByBill(params: any) {
    return axios.get('statistics/bill-share-rank-by-bill', { params })
  },
  async userPlayerCountRank(params: any) {
    return axios.get('statistics/user-player-count-rank', { params })
  },
  async userCreditRank(params: any) {
    return axios.get('statistics/user-credit-rank', { params })
  },
  async dailyActiveUser(params: any) {
    return axios.get('statistics-daily/user', { params })
  },
  async downloadPieData(params: any) {
    return axios.get('bi/download/pie-data', { params })
  },
  async beforeDay(params: any) {
    return axios.get('statistics/before-day', { params })
  },
  async userActivity(params: any) {
    return axios.get('statistics-daily/user-activity', { params })
  },
  async userActivityBill(params: any) {
    return axios.get('statistics-daily/user-activity-bill', { params })
  },
  async userActivityPlay(params: any) {
    return axios.get('statistics-daily/user-activity-play', { params })
  },
  async downloadHour24Data(params: any) {
    return axios.get('bi/download/hour-24-data', { params })
  },
  async downloadTrendData(params: any) {
    return axios.get('bi/download/trend-data', { params })
  },
}
