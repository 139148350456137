




























































































































































































































































import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'
import config from '@/config'
// @ts-ignore
import vueSeamless from '@/components/vue-seamless-scroll/components/myClass.vue'
// @ts-ignore
import ICountUp from 'vue-countup-v2'

import moment from 'moment'
require('moment-precise-range-plugin')

// @ts-ignore
import MyMarquee from '$ui/components/my-marquee'

import ChartModal from './stadium_chart.vue'

import CameraItem from './camera.vue'

@Component({
  components: {
    ICountUp,
    vueSeamless,
    ChartModal,
    MyMarquee,
    CameraItem,
  },
})
export default class extends Mixins() {
  @Prop() stadiumId!: number
  @Prop() hideStadium!: (...args: any[]) => any

  chartModalVisible: boolean = false

  thumbnail: string = '/assets/cameras/3.jpg'
  url: string = ''
  accessToken: string = ''

  playerModalVisible: boolean = false

  classOption: any = {
    direction: 2,
    step: 3,
  }

  stadium: any = {
    id: 0,
    stadium_name: '',
    created_time: '',
    ground_count: 0,
    default_ground_price: 0,
    rent_date_start: '',
  }

  total: any = {
    ccount1: 0,
    ccount2: 0,
    ccount3: 0,
    ccount4: 0,
    user_count: 0,
    ccount5: 0,
  }

  ai: any = {
    ccount1: 0,
    ccount2: 0,
    ccount3: 0,
    ccount4: 0,
    ccount5: 0,
    ccount6: 0,
    params1: '',
    params2: '',
  }

  cameras: any[] = []

  showChartModal() {
    this.chartModalVisible = true
  }

  hideChartModal() {
    this.chartModalVisible = false
  }

  showPlayer(e: any) {
    // console.log(e)
    const dataset: any = e.target.dataset
    // console.log(dataset)
    this.showPlayerModal(dataset.name, dataset.thumbnail, dataset.url)
  }

  showPlayerModal(name: string, thumbnail: string, url: string) {
    // console.log('eee')
    this.thumbnail = thumbnail
    this.url = url
    const d = window.dialog({
      title: `${name}的监控`,
      url: `/player.html?url=${this.url}&thumbnail=${this.thumbnail}&accessToken=${this.accessToken}`,
      zIndex: 2147483647,
    })
    // console.log(d)
    d.show()
  }

  showMoreDetail() {
    const d = window.dialog({
      title: '场馆更多详情',
      url: `${config.serverapi}datav/stadiummoredetail?stadium_id=${this.stadiumId}&host=${window.location.host}&authorization=${this.$store.state.user.token}`,
      height: 600,
      width: 1000,
      zIndex: 214748364,
    })

    d.show()
  }

  showDetailList(title: string, item: string) {
    // console.log(title);
    let tparams = ''
    // console.log(item);

    if (item == 'aiorder') {
      tparams =
        '&total_money=' +
        this.ai.ccount4 +
        '&params1=' +
        this.ai.params1 +
        '&params2=' +
        this.ai.params2
    }
    // console.log(tparams);
    const d = window.dialog({
      title,
      url: `${config.serverapi}datav/stadiumdetaillist?stadium_id=${this.stadiumId}&item=${item}&host=${window.location.host}&authorization=${this.$store.state.user.token}${tparams}`,
      height: 600,
      width: 1000,
      zIndex: 214748364,
    })
    d.show()
  }

  renderDate(date: string) {
    // console.log(date)
    // console.log(moment(date).format('YYYY年MM月DD日'))
    return date ? moment(date).format('YYYY年MM月DD日') : '-'
  }

  renderDate1(date: string) {
    // 未申请，升级中，待升级
    // @ts-ignore
    // const diff = moment().preciseDiff(date, true)
    if (date) {
      const days = moment().diff(moment(date), 'days')
      return days
    }

    return '0'

    // let str: string = ''
    // if (diff.years > 0) {
    //   str += `${diff.years} 年`
    // }
    // if (diff.months > 0) {
    //   str += `${diff.months}个月`
    // }
    // if (diff.days > 0) {
    //   str += `${diff.days}天`
    // }
    // return date ? (str ? str : '-') : '-'
  }

  renderImage(img: string) {
    if (!img) {
      img = '/assets/cameras/3.jpg'
    }

    return `https://data.taisam.cn${img}`
  }

  async mounted() {
    // console.log(this.stadiumId)
    const res = await this.$store.dispatch('datav/stadiumDetail', {
      stadium_id: this.stadiumId,
    })
    // console.log(res)
    if (res && res.Data) {
      this.stadium = res.Data
    }

    const res2 = await this.$store.dispatch('datav/stadiumTotal', {
      stadium_id: this.stadiumId,
    })
    if (res2 && res2.Data) {
      this.total = {
        ccount1: parseInt(res2.Data.ccount1, 10),
        ccount2: parseInt(res2.Data.ccount2, 10),
        ccount3: parseInt(res2.Data.ccount3, 10),
        ccount4: parseInt(res2.Data.ccount4, 10),
        user_count: parseInt(res2.Data.user_count, 10),
        ccount5: parseInt(res2.Data.ccount5, 10),
      }
    }

    const res3 = await this.$store.dispatch('datav/stadiumAI2', {
      stadium_id: this.stadiumId,
    })
    if (res3 && res3.Data) {
      // console.log(res3)

      this.ai = {
        ccount1: Number(res3.Data.total_str),
        ccount2: Number(res3.Data.money1_str),
        ccount3: Number(res3.Data.money2_str),
      }
      // this.ai = {
      //   ccount1:
      //     parseInt(res3.Data.ccount3, 10) +
      //     parseInt(res3.Data.ccount4, 10) +
      //     parseInt(res3.Data.ccount6, 10),
      //   ccount2: parseInt(res3.Data.ccount2, 10),
      //   ccount3: parseInt(res3.Data.ccount3, 10),
      //   ccount4: parseInt(res3.Data.ccount4, 10),
      //   ccount5: parseInt(res3.Data.ccount5, 10),
      //   ccount6: parseInt(res3.Data.ccount6, 10),
      //   params1: res3.Data.params1,
      //   params2: res3.Data.params2,
      // }
    }

    const res4 = await this.$store.dispatch('datav/stadiumCamera', {
      stadium_id: this.stadiumId,
    })
    if (res4 && res4.Data) {
      this.cameras = res4.Data.lists
      this.accessToken = res4.Data.access_token
    }
  }
}
