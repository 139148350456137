import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

import api from '@/api/stadium'

@Module({ namespaced: true })
export default class extends VuexModule {
  @Action
  async list(params: any) {
    return api.list(params)
  }

  @Action
  async detail(params: any) {
    return api.detail(params)
  }

  @Action
  async settingDetail(params: any) {
    return api.settingDetail(params)
  }

  @Action
  async todayBuy(params: any) {
    return api.todayBuy(params)
  }

  @Action
  async todayPlay(params: any) {
    return api.todayPlay(params)
  }

  @Action
  async dailySituationInfo(params: any) {
    return api.dailySituationInfo(params)
  }
}
