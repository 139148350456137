






































































































import { Vue, Component, Mixins } from 'vue-property-decorator'

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

// @ts-ignore
import wxlogin from 'vue-wxlogin'

Vue.use(Antd)

@Component({
  components: {
    wxlogin,
  },
})
export default class extends Mixins() {
  form!: any

  appid: string = 'wxeb9b676a88d32a0b'
  scope: string = 'snsapi_login'
  redirecturi: string = 'https://wechat.api.taisam.cn/v1/wxlogin/admin'

  captcha: string = ''
  idkey: string = ''

  showWxLogin: boolean = false

  beforeCreate() {
    this.form = this.$form.createForm(this)
  }

  handleSubmit() {
    this.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        // console.log('ddd')
        const res = await this.$store.dispatch('user/login', {
          idKey: this.idkey,
          ...values,
        })
        // console.log(res)
        if (res && res.Message === 'success') {
          this.$message.success('登录成功')
          const redirect = decodeURIComponent(
            (this.$route.query.redirect as string) || '/'
          )
          // console.log(redirect)

          this.$router.push({
            path: redirect,
          })
        } else {
          // 刷新验证码
          this.refreshCaptcha()
          this.$message.error(res.Message)
        }
      }
    })
  }

  refreshCaptcha() {
    this.$store.dispatch('user/logincaptcha', {}).then((res: any) => {
      this.captcha = res.Data.base64Image
      this.idkey = res.Data.idKey
    })
  }

  changeTab(key: string) {
    if (key === 'loginByQrcode') {
      return
    }
  }

  mounted() {
    // console.log(window.location.host)
    if (window.location.host === 'dv.taisam.cn') {
      this.showWxLogin = true
    }
    // this.showWxLogin = true
    this.refreshCaptcha()
  }
}
