




























import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'

import MyList from '@/mixins/list'

import XEUtils from 'xe-utils'
import { Modal, VXETable } from 'vxe-table'
import 'vxe-table/lib/style.css'
import zhCN from 'vxe-table/lib/locale/lang/zh-CN'

VXETable.setup({
  i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(zhCN, key), args),
})

Vue.prototype.$XModal = VXETable.modal

Vue.use(Modal)

// @ts-ignore
import MyDvPage from '$ui/dv/packages/my-dv-page'
// @ts-ignore
import MyDvBox from '$ui/dv/packages/my-dv-box'

@Component({
  components: {
    MyDvPage,
    MyDvBox,
  },
})
export default class extends Mixins(MyList) {
  @Prop({ default: '弹窗' }) title!: string
  @Prop({ default: '90%' }) width!: string

  @Prop() pageVisible!: boolean

  modalShow() {
    // @ts-ignore
    this.$refs.modal1.maximize()
  }

  handleSubmit() {
    // nothing to do
  }
}
