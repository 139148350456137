




















































































































































































import { Vue, Component, Mixins } from 'vue-property-decorator'
import { Icon } from 'ant-design-vue'
import config from '@/config'
import moment from 'moment'
const numeral = require('numeral')

// @ts-ignore
import ICountUp from 'vue-countup-v2'

import Pie from './echarts/pie.vue'
import Map from './echarts/map.vue'
import Timeline from './echarts/timeline.vue'

import Stadium from './stadium_new.vue'
import Graph from './player/ecgraph.vue'

import VModal from 'vue-js-modal'

import ReconnectingWebSocket from 'reconnecting-websocket'

// @ts-ignore
import MyDvPage from '$ui/dv/packages/my-dv-page'

Vue.use(VModal)

Vue.use(Icon)

@Component({
  components: {
    Pie,
    Map,
    Timeline,
    ICountUp,
    Stadium,
    Graph,
    MyDvPage,
  },
})
export default class extends Mixins() {
  stadiumId: number = 0
  stadiumName: string = ''
  stadiumVisible: boolean = false
  graphVisible: boolean = false

  data: any[] = []

  maptotal: any = {
    province_count: 0,
    city_count: 0,
    stadium_count: 0,
    ground_count: 0,
  }

  // 平台累计
  total: any = {
    ccount1: 0,
    ccount2: 0,
    ccount3: 0,
    ccount4: 0,
  }

  t1!: any
  t2!: any

  stadiums: any[] = []

  ws: any

  is_rd() {
    const is_ext=this.$store.state.user.userInfo.is_ext||0;
    return is_ext>0;
  }

  renderNum(num: number) {
    return numeral(num).format('0,0')
  }

  // 地图统计
  async loadMapTotal(params?: any) {
    const res1 = await this.$store.dispatch(`datav/maptotal`, {
      ...params,
    })
    if (res1 && res1.Data) {
      this.maptotal = res1.Data
    }
  }

  // 平台累计
  async loadTotal() {
    const res2 = await this.$store.dispatch(`datav/total`)

    if (res2 && res2.Data) {
      // this.total = res2.Data
      this.total = {
        ccount1: parseInt(res2.Data.ccount1, 10),
        ccount2: parseInt(res2.Data.ccount2, 10),
        ccount3: parseInt(res2.Data.ccount3, 10),
        ccount4: parseInt(res2.Data.ccount4, 10),
      }
    }
  }

  showStadium(id: number) {
    this.stadiumId = id
    if (id && id > 0) {
      this.stadiumVisible = true
    }
  }

  hideStadium() {
    this.stadiumVisible = false
  }

  showGraph1() {
    this.$modal.show('hello-world1')
  }

  showGraph(id: number = 0, name: string = '') {
    this.stadiumId = id
    this.stadiumName = name
    this.graphVisible = true
    this.$modal.hide('hello-world1')
  }

  hideGraph() {
    this.graphVisible = false
  }
  async loadStadium1() {
    const res = await this.$store.dispatch(`datav/stadium7`, {})
    if (res && res.Data) {
      this.stadiums = res.Data
    }
  }

  async loadWS() {
    this.ws = new ReconnectingWebSocket(
      `${config.serverapi.replace('http', 'ws')}datav/websocket/`
    )

    this.ws.addEventListener('open', (e: any) => {
      console.log(e)
    })

    this.ws.addEventListener('message', (e: any) => {
      // console.log(e)
      if (e.data) {
        const obj: any = JSON.parse(e.data)
        // console.log(obj)
        if (obj) {
          if (obj.action == 'latest_bills') {
            this.$store.dispatch('setLatestBills', obj.data)
          }
        }
      }
    })

    this.ws.addEventListener('close', (e: any) => {
      console.log(e)
    })
  }

  async mounted() {
    this.loadWS()

    this.loadStadium1()
    this.loadMapTotal()

    const callback1 = () => {
      if (this.t1) {
        window.clearTimeout(this.t1)
      }
      this.loadMapTotal()
      this.t1 = window.setTimeout(callback1, 60000 * 4)
    }
    window.setTimeout(callback1, 0)

    await this.loadTotal()

    // const callback2 = async () => {
    //   if (this.t2) {
    //     window.clearTimeout(this.t2)
    //   }
    //   const curhour = moment().hour()
    //   if (!(1 <= curhour && curhour <= 5)) {
    //     // 凌晨1点到5点之间不要实时刷新数据
    //     // await this.loadTotal()

    //     this.t2 = window.setTimeout(callback2, 1000)
    //   }
    // }
    // window.setTimeout(callback2, 0)
  }

  beforeDestroy() {
    if (this.ws) {
      this.ws.close()
    }
    if (this.t1) {
      window.clearTimeout(this.t1)
    }
    if (this.t2) {
      window.clearTimeout(this.t2)
    }
  }
}
