export const male: string = `path://M96 0c35.346 0 64 28.654 64 64s-28.654 64-64 64-64-28.654-64-64S60.654 0 96 0m48 144h-11.36c-22.711 10.443-49.59 10.894-73.28 0H48c-26.51 0-48 21.49-48 48v136c0 13.255 10.745 24 24 24h16v136c0 13.255 10.745 24 24 24h64c13.255 0 24-10.745 24-24V352h16c13.255 0 24-10.745 24-24V192c0-26.51-21.49-48-48-48z`

export const female: string = `path://M128 0c35.346 0 64 28.654 64 64s-28.654 64-64 64c-35.346 0-64-28.654-64-64S92.654 0 128 0m119.283 354.179l-48-192A24 24 0 0 0 176 144h-11.36c-22.711 10.443-49.59 10.894-73.28 0H80a24 24 0 0 0-23.283 18.179l-48 192C4.935 369.305 16.383 384 32 384h56v104c0 13.255 10.745 24 24 24h32c13.255 0 24-10.745 24-24V384h56c15.591 0 27.071-14.671 23.283-29.821z`

const robot: string = `image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPAAAADwCAMAAAAJixmgAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABblBMVEX/////////////////
////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////v/+/f39/f39/P38
/Pz7/Pz7+/v7+vr6+fr8+/v5+PhtbGxWU1OEgYDz8/LS0dFfXVyfnp2qqKjBv77q6ej5+fnj4uEQ
Dw8AAACYl5dlY2MCAQPt7OtCQEAdGhjo5uWko6IIBgbv7+4qJyXx8fDg3t35+Pf39/f19PROTEzc
2tn29fXm5eTMysqNjIyzsbHi4N82NDN3dnb29vbs6urw7+/i4ODp6Oh6enro5+fr6en19fX08/Py
8vHy8fDl5OPu7u3u7ezr6upmVDO2kD59ZjhURy+OdD2hgTxDOCb8uyT+uhv5thvPnzXvsyvkrC/k
4+Lh397h4N/h3t4goVW1AAAAJXRSTlMABRIaIjJceZG2zMjz6KdzKAtHhprB3PjWPs/g+1Ps6mxl
79LlNCXICAAAAAFiS0dEAIgFHUgAAAAHdElNRQflCgsRHQIbantGAAAqZ0lEQVR42tWdiUNS2R7H
U8ulzFxaJ22Z10VFIXK0EtDCNBGXxDUBQwHBN0szzZuR/O/f+S1nu1wEC1N/M8+a3kzx8fvbzu+c
e+6VKz/Emppbrl5rbWvvuH6j0zHs5o0bXe23WruvtvQ0/ZhPcvbWc7W3te/2nbv3HG/zwZfO+x23
b7U+uHreH/Z7WVt62zp+etjv1GmdD2903XrQfDm17uke6LvRWTertv5HHW293ef98U9pzQPtdx6f
nlXbw46+B5dF6KarA103730PLfv33dsPWs4bpra1DNy+yYmoAXa9r7f5vIlOsp7errsNQpV27377
tZ7z5qpi125drxdj0NOq/Mv9Ha0XsFw19XY9qoPUN1jbfD6fm/1u+7XzBrStZeBGHWmqHtpqcnd2
9V4cz25pu/9NLnw6aN+dJxcDuflJLdwqyg4Jwy9sNZn7rw+cP3Lzk5++hRYJh11WBdv83e60nm87
0lRDXW9SCej3Mka3kY263tF7jry9N06Hq1hNwhFvapfYGrm/67wy9rWuE5YGPm9WjYk2atmINIPa
y7cftZ9Hy9ncd1Ld9Xng+i3a0eqmoDmu3cQ+527rD89eD+7U78ykblXaAFgwwKahpc4Gsv6GdnX/
WHnbO+ukNWEtUsF4kpnUlm/LP+Xm0x8o8sBPdeNqXlAW/oXnAfzQwSAqRT94wQek1MisiVX66vhR
yaulvXob6avmyahu6MXY2C/jgxMvx8ZeDQ2+HhsbmxwKvBobexkOREIh/Fv8FamgBmisVrbKN5/8
kKL84IRaVCVRsS8PRaemp18OOW+mp6d/cXxvp6djk87Mu+np2blAyLAImsXMyEO2yL6us19GNbX9
XB+txNXlJ+i8n5+P/eI4L6bnpyad+Lvp+YXE4OJsbHpsPAiIS2DLoSWTXEGPjBKz7dh3B86Yt/m2
rw5eO3JVKnZexOanXjsrH6bnZxPO6kIs9iHpm4jNT78ZjESYkaDFX0uSm4hB5xFK2pZbd946U7c+
oRhZ+hrOrEvP80EgXUPSdxFnfX5++pfnQxux+flNB52YAIF1GUwyGyqPGMQSuesMu5DWapNIn1fs
muKC+ba2BanjrE/NT398PvRqej626fjHYrHZ9cFlAF1mTqBeXt5ZNv4ZfRtlNvya//D/nFW2bmrr
ry2vGbwSd5TaCWdyKjb9YnjwNZAOhl7GYlPrvsB2bHo7FVj2sJ0dZl5Snh2Ujm359aOzCeTmrnpw
XYlZdU7iwzqbsfnY66GltyJnTQzubM9Pb0edidn56ZfODtCRAahSd5l/iXRWnq2ROZDbziCQuzvq
yVY2byAgYxgd8pfp+fmJwfF3sdjC4mAUSIecV/Pz87/4drxME9vIpLJF3N/e8Lar+/op9JWYIwZv
MJAG0szwriAd23FeT83HPjnORyH3pl+jVUPGn4u2pAqx09Xg+fWDh7XTlZ2r/KP0o+wfIqNZ0HR8
cFJkZyDVJWpPwyaT4m+0fU/+JUra7NdDRknuaGgPUpXXMzkDrm43pA1PigZrw3F+EU78xvF/mJ6e
WvStzk5Pb69YWElpKwK+UvElKlOQrqXIRHy9gcStz07lzboiKVromVbfv3+/GnDGpkUd8icn37/f
DIbCr9+/nzQ499lW9pP7wLwifp5U4hM0q0x+Paz9unHEA5219JXyEq7fil3mjYSGxX/hD6a3p2Pb
z5eX4b/f2QnB72Ipi4wrbPCP+AP9/1Jng9hv1KfrDWpBBm7Wg2vw+j305T5x2bc+G4u9HDSrzg54
btKwlUrb3+dvCTm2imShsUHc0RDi1s6Tec3g1eoGLXG5cRTNorMpFhAbo3ZO3jdEdVsul5PQkNDI
wTXyiETGz/RTA7z6wbNa8g7Z3lzhy0GNu7w8uCGK8XrQylI7HL4GpYLNreTG4Qu7NqQyQl7CdtPM
XY2J4yr52UNeL1+WCtMiSPBG0mOiDmUMYEpUFaqOC8sBaw7/AX9QYb3DyCEZyTQPaUQcd9+toa+L
1w1MqyCmFRaMLsSmP8yEjBLk4c05iUxGyOMKGf7Hfo1uLSdAFMff1YFU6a/cxWjE740bZFem5Q9Y
YBUXha6aa0srUQ1kA17lb1vkYUXc9R1dZnNHffL6q8obovmFWgE9fw8LCMeqvC5px082kBmpmTiC
qWvErzVu/2benq7T6+sKXoXLLj36ViwKJwd1ehbJ1w7enJey+Xw14mVJPKzC+N6TbwVu852EO1wb
l0pvSEWwcOl3semFxaChsA1rY47nC3lkLQjL0y8xM8Yz5y726iHZZnZ+4/q49V4Nea2domq8XI6o
zQhC7/zBMTrmitRsWgENmPknUmskzqHI3F0LjYcU8bNvmoFc89g78hn6+qviRvSEyhAX13cHr1+9
mox4dxuWM0vUAlu+kKAfx8VPZe6GbkQFsmxBfN9YnFrunCJ83bjBiDmDNIY22DsntUeb/pwznZmI
CTKRSOiv4rtQYJWROMmBDE0XLiUwDm+fmrfp9kn6egiMMw0PXteUyi5I1f1ZiitZ4Uf6Ip17PEcJ
DIhh5hUYMYh9T0+dsKrzqqWRxsWJBusbDOrg1em5cjlfEcIVwUugXgb/p67LScpc2FjLJvPnUx4U
ePBzTX1HPOQNCtyImauUwmpAZwHr8HXrWwU4Lf5i4rwiTmLmCpLGsjj9dKqOq6Wyw6oTN6hosR5J
3AqHdveThk+jegLIBgVWgE0rYkNkjmMYtSji04RxU/sJ7dWQtbddjVe5NI5dLV73wjcnzeXQSmFE
TQMp/i9BKkM407+/wsRUjqVT958ijCtHHPYgdsQOXxuX2ytXenbF776rozTjV7q0Fhdp0/JLOp2W
0Im81FiUJ+qrR1Tielb3UrH5PzXaDVc1UrwRxcu4S568rqbDpe84Vl0jgtOeBsSQw41AXkKNKVXj
MqLe8Xz7yfr6vfQNWrwhk9c7QdsBXNFhWQFcxaTGAI2ZS2hsrZxa68zQ7pbSp3EVrw5fU16rHhkb
RJ4Ke3eVeaqzCe3SbovHNTFpXODMhatFg/hhXU7dfKeaP9vl1+DV8rp4eZbsJfF+VYldCUsiC854
ugg/FAE5rjRWXq0Sl5K4vR6n7qsev0g6ajbPkjdiNJYhhQ7ds/e2oDWlBHidqvO5PJk7U8dLxaJg
hb/Fj2nxRWYvLFE57KuxyVQS36uj/bj22BuYeVFg05/9tOoeViM92HKRSynx5flzv9998izgOrXE
jkGuoXzA6sVyK8/9gWSukC4BdgnJiypf56Emy8QFHResnOBj3ag5/qhY9LvWRyNqVCdw/UOOk1xc
n3zzZuNTo+xjFfu0sfFmc301lRxPCFYUuxiX6RrWUJi4BHGQJCbi/lu1gHv7q/GqWR1vljlO5GBy
48P2wuzU9I+x2NTswvbLjclMaScv/Bu1VhWKNJYdFzp1PcW4yXUmyWfXIwpgOIHkBNffvJ2K/SBU
Czu28OLVRC4XF8Dg2py8oLnOrfAERAD72an7TgZ+UnV9ZOgrxM2+eTcr/uhz4CVb+PB6brwAyKUS
NmDUaeZU/6HC+OcTpx/N90+IX95aGHWciY+z58fKOs9vb0wkEbkoazI2IFAIaUeCnbqrfoEreUV6
HnTWX06dp7jaZl9M5OOgMdRlDmPUmFpMP5WmzgcnCPwfL2AqSBS/jhP9OHveoNJisdmNgxWBTL0X
J64kLiNYYmype04nsGo4hMBO6PXCeWPa9m6zEAeJi9R2FcipMW/JKO6v2n203PfIWMbe4JAz8W7+
vAndNvVidbxYjMtkLVL1fnJZNlzk1B3VgNs8BB7iBTDkZv/7C+PNpm1PAnGRq1N+XLSYNnC1+VbL
XQ9/pvWv0Pe5c/j2ImQqD5v6pZTAioxdt0hcUI0jMKke5kTtvYYYcDyBKX5HndUP5w1W1WIvDvLY
gtACOSd7apwFQKL2rMV2k2V3HKOjvvXt88Y6ycZEIJeI2MhbIwzs3W5ZXbSrII06kxcyfLVtr+ek
xlSblk2Jn3lsvTR1VTr0sExYF543FtteR42xqybgSCDgl1HcVgls7p2ZFQnj96Lzgm2v5kSjSatF
1W/JdstjXXyr0qFl/DoTF6zb8LTYh2gCVhK4PBbrpmWeb1VpPnque2do4c+Du+/OG6Y+e3uYBqdO
p3HZhAMuVZkqplu9vkqBKWENjb89b5J6bYPCWHTV4zjEDDKxYHrk3mqqTFnKoX85b466ber1uHJq
FcXcbrk2Xloeuni5IgnezQvXPle3hfUCVOM0zgJwvhWUaeuOq8vqd6VomaGdg8uQsJSNldLUVBdw
3BPRPv242yrCt90lSS75n784b4ZT2fybfIk6LrFqEgvjiG63npjAV595ZiyowJfIocFmV9OlIkuc
BIkVsDUHGKhI0Zyhdy5JRZIWi70oUN4q4BpCpi1oL02fVjna57Mz9KtLJrDI1JMrnLe4FquG2sjT
zTfdESxbjgu9RPK2FyWcfyQKsjL5WWJj8DFwrzKCcY305rw//TfY1CTsP7HEGMXcbd3US6Z2dw3m
IVbuUpUktthL0VLHS1riAJRiAdyv9sd77riA5SLp/aWLYLCp9XRJNNWwaFLLYuyn1bme7se2R3PG
Gl6+uEOdkyz2MVmCvVToL0VDHRESUxDfaK4oSpbAvolLsAr2su1MGn06oSYfwzihVttMfR4pa3Q0
MLRR+5sZq/0r32kVv2Gs5h8xv1mYUWlLzQEE34BreuczU3QguFKr6Zj/76+//tcK86nffv3190YG
/uwfv37+0wJc+PzrH7Vy6cfSjJZ4ifYSB/Usr+We7dDDcEYhEByaqPHJ5//468uXvz4b2+Gzv375
+8tfvzUMNzb7P/Eb/vNfg/jPf8Wv/FuDeOFoBrbYRBSv0ORjhPaZ7jTZa38FDA4ddN7UcJ3Zf/4W
9tdn9X2Z/fwFfqXWxzmF/QG/oUkMvOJXPteQYrI4A9uomLYQeASBOylrtXmlrGBwcKzGp5lFPEUc
m/qVfuGfxvXf8neUxMT795dfT/7PYhsFkJjztFgy4fMBImZpstVhAYPAEMFDh7XaSlJYEc/yp2u4
wlpj5q2p8PSHHPk0jnpwyURBjNPanp9MYO3RNYvSFH8cIpb6/v3Xbw1L1LGFf01ixVvzW7pdFmkL
gWl8KYHxNMDVhy6BMWUFndc1s+3UZ008ewa8glgygldr3j9r/RGz64kU+nQBgxj6aQS+0WRssfjM
GhwM+mpXYZNY/eyPRhbiWOx3RfyH4v299gfbzAFwHArTPgHjAbq7sH5odSqB4Yzsxzo+kCLmHxrM
C8i//yN/b/6T/vm9jj9iYyVFhQkmPcsR2Xpgr9XnytF0KjiQr5WkbWLJ2/DlhtZY6lsHb+xTOkU+
PT4ug1j2Wmp+p3I0evTzUn3FxSJuuL5olsb/1PZnsBczKfLpwriVtZ4ap4V5oYQ1SQCv1VdcYlOf
z1Jf/CN+/1f9EXXpK+xldiY1M0OFSSwgqNei5lIddJAejQIH/Yv1fp5Z9XH+d0aHLmO/KeB6XWjs
IOUJ3NVz5arZSQ/5Tw08q33a6DIban9qha2++gR7t5gCnwbgcVwiym66+co1k3cIzzbDOe96gVX9
PTviP82sVSfxu8WZw9QMdpc09hilblrUpVbbo08HrPrnMyT+087S9RETMPi0Ddx5VS4dfBo4AAf5
/av1fBjdX50ZseL96zQaC5c+PJyBMYBK07h8cLrlxNKnl8LBeoG1vn/9YfXVZ8H772//fqlf4w/R
wz1P4AG5VtILB0hZocjoUe2BltE/m11mI3O12T+bfXWt/+5tVgCnABiayx0N3Hrlug1Mz5oJ4FSt
xiM2b/XPmviPhuHGZnX/HNM915d//qwJPGcAr+zIzsMnFog3NDB6dICAA+maM1q1HqbiqIgbvx7+
m/pn1VfXXA9PfywBcAqBcwrY53Par3RawOzRYn2xXPNkh5x4yP5KEjdu4hGjmFH9s9S41sRDLB4K
u+E9SNMEvEwHLxHYqEq4UmLgyGDNox0809LNDxF/+bdx42yaaRnrQSL+Uitq5l/ldsNh0WxVAHdV
ABNvKFJ7Iy32G0wtfzOysojqLyKJNox3euF/4jc01/uQub58+V+toJmdLGWFwikoxAw8SsDXDeAh
EzjkTNbOtr9//mwlzNjUb58/19ne12ezf7im0LEF8Ss1XWhhIsXAxTQuEBXwjSvuPksCPy/XzD2x
WMUuQO1tgVNbrPIfa/0Zse29sAs46AXs5yRNDwJGLtlpB9M+prMKGNZLvEt8EvDy8iU6kOa2+c29
bHb3tMCBy3aAR9vsalYAhw9rA4+MBhXwcvEy7v+jvZ2bqwdYluEIAy99Ou8P/q32PiWIATg1YwGL
BaI38BICD11Wn16YCNcFLF2agXciNXeXLqi9TK0p4FIad8VPBAbaEDyZfjnzdGx+0wbmBTEB375y
szpwaPVSHvJ4d3CAwLsIjJPpkAJu5+WhASw8moCTyeeXUuL3YQ1cKiVshasBLyNwaPUSVqZ3q3Mm
cBo2xZcEMN1Y84TPlXoDJ0cvncSx+VepgzVBTK1WqZiwgVt5iOfTwHQPJV16Fay9grhoJiL4iIFx
5IHbaRq4l59XIuARl8Ir+4HLdrx0anPu6EBkrTWxesA6jA8DLIUCDNzNg3hvl15ZSc5csuOHH7NH
R0eCl4HBpWGmBcDA+7jlSrcX8A659MrKyvP1H3UtSUNse2KNgOds4MgoAd9vuXK1U+2VUmsZCeEd
djs7+3gPTnDjgj4m7WXzm7tRwQtZWqTpw8OZUlwAwykPrkodPfzgvwIO2sC5lZV0XUcBLoTFPq4d
RaMQwwAcTh3SEM9stJqu9HRUuPQyuTQqnBu/RMX45eKB4BW2BoUY5rTQWZrAferIg9wND2BZostj
+Xq+4Pol6TBFABMvJOm53bBcOxjAT9VztBo4CEmLgPl+6+VXlyJxzU7ORQUwuDQoDMBmZwm8eKHY
gDmmlUkLTF/oHXpzCVbGs5vAixIjcHhvD5J0IYdHHugRxEdwbOlBp+w8eGspRK88ImC+b+7ThU/V
U68lb1SGMAKP55JwGxF59P1mdfTQBF4yFOa7NfOfLrbGMclr5qzDFFYlAOYQvqMPxHOaHh2lxoNj
mK+4zhdyO28udBzPvs5mopL4gIDDNOChJD2Cz3nc1s/h8dYD7DwYwHyHKtw0mbyY99KQbU+uZbai
mShCa+CUcQAAgZ/o6w7UdqkJTB5dgMs1E4md9Qu7FTE2cZDZ+hrNAPERdVrZrAjhmTguDkMhupXY
6afHWh74mFgCh9zAdL/kTvnthQzkqU+LB+Xy1tbXDAuMrSXuD8cTcEwLOmlK0vREbfMjGzgICicZ
OAcXtvOFmrnSxgV06+3XB1HA3SpvoUtTGcYZnshZkKT5bKnopOkhj6YOVYiHaYqHayWMYXX1Md4g
WkhOvLxgIk99mlgrl0FgEcRRK4RFJw3npY0+q816UIsPtWBdYmBUOE/vHKDrUleKry/SsDr2YXMt
CrhfERldWgq8Z40sIUep20t6zeYywEEMN8bircD6rme8XTC59ubCLCY+vPqK8gpawZwpmwJD20EH
D+ExHhRYXUZMrYfspg1gTFqkMF1cDpZPZt5sXwDHnnr3fjUb3SqXFXImYxclXColZVEalM9pGUEM
Yy25QMSBB2Vp5dB873FiJbv54nzTV2zh4+TR2taWhIUvWJWitPzf3QvjPIv3lagK61u1+sxeK8BZ
awVbLZTYvNc6no4X44nlxMSbD7PnpPPU7Mv362uGupi0OGsd6RCGKrxPRWlYLpXIHqgxz7BePmAQ
s8LGVd4IXIRvXmL1/dt3P9q5p7bffdycWFs7iIpEZQosvqJLH+AELxzeg2lHHp9p4XnWQ32vRdN9
DYxjLZ7Dr3AV1sB0f3cRrBRPJ5cOVydfb3x88eFH2NiLTxvvJycOZsQyIZMx5eUYjuoqDCuHomuX
xbyzVU09oBIL4CWOYZS4YLh0sRgvwU3eJXiCQvyQLiR3xtPxmRQ8YsAGD4XhVch8NbD9qgLbX/S9
7/R7iv9c/FaHwsLh3axIPnNCL9E6iUVfNLom1rhZmFoJYA7fY/zbAFZt1qHw6EJOe/SgdUX+A8fV
XWIQc9bKU86iz4W4Rbr2Fz8iosH17fKtIvI1G3jt+Xg+pw1e9DbOr8PKmSbfRUPfCAwZYE8JC4fD
WWFzc8B9hNxgMl0tLh4LY4eWKWsN2iw1zopwjn5o3ovX/JP0aX8FcIEkZiXgwzCoacLBi+mi/UIC
Bi+MF8ZPNKvOi28pKS1g94Tt7gLu2prJmxH5iQJXsGqBvyqP3sVtJWulhANLw/rMIOaspd4aUzAV
FrjFUtGLOG4Q22IX8tVgjbfQpE1eEHfvcE/49S7Jq/w6igHMDn28KCRWWTpDHi2A4TwLP+4QofuW
Bvvt94r13pO9h5xriSAGYvQ2WYVBXwk74ymzfgeDQpbQhXyh4Ba2YEV0nCMZ5T3c2xO0hr6GwFsq
YwlgkBkELmeMhQMLbHQdrrvTm+9r4BEFnARgWjoojzYAZ9zceFG9gWxCS+yC9VIDu+IVWd4Z5BXu
bOvLAm9l2J8hggVvmYF52AECy64Dtw0J2H0jb7txlkdKTD6dN0JYhe9MiRIy5lWLGoKcXkLh0rma
yW9P0cYlb1a4zCuaqUyG1QV/lh4NSwc53eEiTG1lJEDv53Hc1x5e65d5mk6YQhQn6RUbRqelPFoC
6x/tBGYonUjXQZsu6rJE6Ur685zJiw6t9AViKk0QwlsZnHUcYMqaSeMEHhdKfjWvNM1YFOs5D88t
pcQQY5xDLZMluOQS2nTvNL8jyvMFLNJxiFZkq3CYvVnrS7S64yB9F9mhv0IVplkHLf1hTwlnHfQ+
RI+rS1vNfppWTEkGBolJX8oppRkWFlBT6IN206Hqs6v18Laim7cifE19t0zcMmesr1tq1rG2K8eV
9PT/KD6D96jy/mFrjYi7xDS3pBVxQn60IgKnWFdgxf+lTMWlZ1MaMzO3F2xReQ3jSn2Jl/MVBnBG
6su8oDDG8NcyRTDu/AuPLlGXtRRxDXcq05ZjrhF1EBewLMXjRDHDn460TSlTDaa7XOF7RjiNIX5c
9uMyBypcM1259M1Ag7XF7Ybh0GUSWG6EQ5fFD/8vBSllGTdLmWmrU3dbtIkIlQkllgrHi8YHNEgN
m/GIZzRGlEsPo3XRv9khFqOwdme7HrG+xyo/y7QFOUumaEhZ+GR4Ek92UE267/kigC5Xt7UEEuPc
slDgpKVieCblzWtJXapp5jcOaA/DKnqlvEZ/lWF3PlYeTTlaliRwaIzgeJqvaAlwynrixcvXPSjg
IOdpbi4tYA/cw0M3c2XB8qBl4EMUF73Zllf3VxnVPUveMvxNVSnDGQuGlSm4kyZnNh0PvV9lShen
scRBGl4mV3B7KaGAi0Z+kaipQ/mDC9os1SS41n3GUBdxoXNmb8669YXpDYVvWQLTV8zSZUzRR2u0
EKYmSzQdS+pFANVegfi0Xz5Xi5WJ0xb2+FQ6i3IpU5rRrIepPfpRfGpcyR7aStsZ3CP6D0leFjdL
a+CK9or7KyXvMS4Mj2WXpcfvM1yDRQQHqOl4XO39NHjHtM8n0xa2l/sMXKCVqmyHZqzPu4f/o7+I
2JZaQsvvQCWtys1GMfJMV5SwVpGYOukyrvw5gsWyAZ7NgmWDTNGDJ7xje8DeKYYTaiwxdZfxIuur
XZFw9/aQlo2xDz28vIJWwZq0lbhbZv+8yhX4mFeGGblqYIdOcEnCp8IHnZvV3zGlonhIL4uTDIwS
yxBWdQRXNRqVPr78FQSnf62S9FB6MrVVRqdh86rwxe5ZdxyLcvG/JYGVQ+Nx0qWg7CpPesVUaz8D
c3/JlYkl5i6Qm0qFqzHDQiv8EtZSK71Zc8OLUduwpLV5j3S3AevfLbkaNAVepJpklCScZCXy4NCi
BnMEd58AbEgMB00joZCM4nFOW0UzSaOSkjRMsCRXeDcc5u/CXlh6uuUI+B8YrJLWA9daLayqGoz6
4pYwzzl2sYnG63eWQhzBvhMiGGuxz5rXQru1T1FMrx7UPp2iqQQOJpRQu9gm0Zdd/MVwmL8V8C0I
87cnrP99DezBq+MXi5HRQXMT/bVcxj1wtUoym0qK4Gc1Xicu260h7qg5igtYmRJK4hmuSWFKOPrj
MwT+XWlArSmltBWwmlaHL8u7yiLDeEcmaDo9Cyfg5VM7qgT7qjRZ2q79rJ1atVs4j89jP11UUwmK
xTCpG5agWf4hi188qenXEZaktVKVkZxVel4sG+qucgeNHh3N0BEHdOi9Gbz3b58zFpak6zVfNN1u
b6yFZPeRz9OSiXrpFDeE0P2GbRainctWMzj36XLjNR5raGfeEn9ldPU9NvRd1B0WbvlThsYHwku0
m2Q00fcGavHyZUR8poc31lakU+MbRNmlD6nfDyt/lqra7ko/07+k3JhcGfU9UIVIeTM3G1so76Ih
MK+TaJFkOjTunokMvYMZmgT2ddTxtlZZmlDiANZiOZBP8ORYxjDmoLB2YoOVuRS0/OmcSatCd82F
KwRWa30jPUuJy4uq5dAt1l6qiO93WCaHxib65rXavPxaHp23yKlzeu4Rl4XpkKaLYU5SiIlg6icY
oHNr9BNi1Jw6cG15MyzvlsQ9tsoRrPoXaXAXlcf9yaFxkZRcwp6S3u5QK2ORdT8zDlCriS0NqGnN
pImFxLuyC56jdY5Wcs2OUvmDylLSlV3OnDF6Z3Jopl2lL4o3Q7vBEMA4qORFUig4whnrTs1305I9
MVpqbD+gpdbTS1gzUacFbVRYjSg07BpG5xwjujQ1ZVWwespOtUjVXhW9UuHjxfKi6qDlnBJaDuQV
GTrIY47BE1/xaJnqt3gvEXZPSeK8bqh5BIX7P2Fe1mUr41NDUrC6YQ+ObF8WX7bUJJbaq1UzPeO6
kP1ZJyxyaLzAgq/sAIduq5OXXzaFEg+P6tmHcmpsPtilD9VaZ05785qnHRx4S+tKzWrnyJhtKI2P
5dxuK6N4eSsJB7OiJIkWiwS+UbMEu5yaH/wQyCEzjLnBVEEMWUs2EnPKjz0EruLGRm7GfdCyPcpZ
VbGrx7JbtGKQj+uE8UYWvv0uIBdJdTu0lakHh9XGCzRcBJzGaa12adqzVvV1zSo4lJlc2mpopS0e
hhXiykGsWgrauIv2RtJalk/NpjGAl7ECk0PXfLe0ZVfvuoihNuW0xKViSa0fZNbKUiNhi7rmzenh
yoYzE7DCXVXBe3xctoaUeLeBoa9qsXzwhNKpDN91QU49onYTc3ISgPvifCYBd+l3d1XK8kjEpid7
EAMtBO9Xueur1wpmrlokectqW+UA9cUlQ6KwQi2l7KHvd5+Ol09RU+LCc/J4ziUnExcPe6i55GGy
bDW4wlZ4sae0tH8igL9uaVpzpWDmZ7ngR3fmbRVaIuXxYZ0AJ+hTBrAZxj4+2SO3E1UxjvMiAgI5
rDZHslLfNdOLDyxdo5Sq5BLB3DLS2/pedizP23H44k4w6ctvDAuM0CnS0wYwh/F9ObQdpoZriaYf
vDBGYjhixIkae8u1ucomykNZM3YxeMtfv2ped6vB7swDrKiMX4O3QG+kDeI10ngkq441Q6Wpajw0
bOyRq/lW3D6Nkd3NugcXFLVGDTJdmZTFTGWc2YDEZCm8SsmqrOXl8KW7WGZmaJvBfCOcD+4n/SbD
xOXTwwDcXoPNNZrZ8q4LjLbCvGCCEyjmNkm1pIzuTOetRK76qnKz2lawpD0uc7ZCeaMGL156V7Df
gDfoPKtnjeRpbf3G2lhqvJ9TRwJki8kaG9sGxqy1klWqa8yblb6uvHx8rNa+W1tq4I7uTDNZzlem
Pz/q/VbeK01yzxgfDlANCDp1muOYvDq8p86gqDy9ZhBHlT/zuXU8rOGiLdOOkV7wY58htwhxz1tO
KEX40sEG8w2HlLDuPf1mXrGMkKnaPPmRpMwFxOqEIO1zMnE1hbfsUrRlVt5juXEkF8C0d1SWtZfD
l9JzluoR6KvzFSfo/vqXDF7W3CE19tPpnsiS6kBwccwaH6ZoCTGnEteBC9kMXgNXnamjY4SLZXJi
3O2WtNRbmdUoi1dH0Ss77fitfEnpKe3qdeOIHj23Rg/p5QvGiEudrOKGS+fpI9OrsfBuGcl5yzjB
fyy3y5BTZWbe8D6SxYhu6IBjHNQ/M+8o897+Tl6TGE8lysPUK4bGSmRj895Arkhakrdctvpn7dpQ
c4/libNoOaprL+DSBR3wakMMX0vf03bQ1YnlaRf50AvM9eRpJi2ytUokpz6qZM7Q+aOvPNowUvWx
+RP4hnDpJV65WthL0WXoFL46Xw36Or6xANvW0qGJRc8VkC1IjpGLMMjkkc9e2Ow/aF1oB/GW/iIq
8FfcLjFN08Phuq/UWTFuls6sgDsntDvLeuRzbjeE90pTi9J4mLwarwmAPadCQQ65Simj6coai6aj
IzcypS326zJ98cTl5zaUN+PwCu+qSNNLpGV6bqA/m17t41f1BHi1KJJ1joBLcjp/iAU5a+yg2O20
u/vARTB1Wrz2h7Z6i4caGXreGxcgHL10c0OCXrfr5m2MvkSsLqGSD2RyRSavjtPBK9rr3sORT9Ze
OLndWm6lRPWQssyKo/JR/P+OaC5JB+yol0R3zlP3vBzU4es0yp/ZKI59xiSTiWW2LqlRJjyfoQ+y
u7ZR7CkHO3ZGZCfp4PgTekr06OjIyFW43Q1rI1V9jXajAfXXbc1dTgUyn9XDtotPFs8YKmcrt0Er
1v5bqHFUntTZksPa6JFUV5YiXApSthpX8ip977U1mFd0me337Pk8bR5jE1KQqyckPqTkZR5iMEty
1MOgk+KpNDoBrippAsjevJtieeHOCS0vp+efv6d/rmpPjOfX1HWBdOQ2TxN6ROZkjW6th1yuJsRd
ltVPEPdARi6eVKFcBclKyFvIYbZifTldPfz29dGJNvDM4+5tugBj3K0ynTQLm+c3Ksbvtt7qlxhV
rhJ25V3gAjePr7unZkPg0nLQufPN699adu26Jh42RN6hZyMSxnMaqrneNQb0es5lIR/xNJOmX2sS
d45xhbqpYpFf/b7vklfwdjU0PdvWctu6Qn9UXoO5k0zK9UQcB7hUlPf0QR33UQ7XtFqaGmor2kO6
NFk4MwRv0oXrczpvNTxdmdb09GfrLQnQaAaX8BF6PM+lBiESWe+XG+Mfz51E49fm8DABnnyCa+3i
5M0g7zL1krr63j/9PPaU1vuTRUz3M2NN3ufnI4wVVIpkBmbjsMPaCUZnJHazeMhJqptI5KU3U++s
3Lmj+6x5RUW+3W8j0wW+y7IPyed5h9E6HGyeQHOde+BvAR2N4DM+e3hmEWeSCVI3ycELuEOM23mr
Yd3zifb0oWN0mvRQeYTuHdtX6SutnxAl5rB5hNRlWQ7ZXdg7AWnx3UHoy0JcU13VWwneG2fuztK6
qe3iMT2eUlQJGx+TyNOeW7ykts75XJd5CM91jItOuImG6jBFtHESV/aRoWAAg1fJ23aG2dltTa1a
5EG/n24ADUbokcUkXXhSKPAzSfaJ8EPzrCUdSsSTT/jzPUWLD/HBw5krWHlB3YBOVlB8f5i8ZFfb
+/WVp0N+eb28WlPwo9aos/GIrJLaJN+Th+jxAQEsufBIKkQuFSJQF/YF/UNS3kdPfqC8bL3yxdSS
eITK8tIStSLqcXr1WLDX+X/zyHypVOTnM/PsyoJW/Hag7uiIUtfn3Os6s97qJOu59VATw/RHxTIi
7+/rx7z4+QH9zJ1lsGXDD+2htHApXY4iV/CiurCvL1sN53rt84RnZFf7flahXMFM/dcKP+GfVw9Z
Wk9qyZsy6LlbeLh4HG4KoCK0BLQSd0gG77Nz8GZt17o63cj0Ti66tZnvezGubSp4PlVLdwWwshS3
QItrBIhdv8K92d7y/Z/6u+xBl8+4V19GcyDI3Yi8T5AudhgnteVD4upnQAqs+4J1Z0fCBiBT6VQF
uH3nEry29fR2/OwYMvsReQRfJhiJhBQ0XimgLrTQdwHwL68QK8AiLtGOKHFR3e7zhmXrlY7tWCoH
sDZHQupOcgLfd1kSUQFWObLIyqMo7rCidR7evgDqKrvW98xxTNfGlhMMB/dkgmaZTMLv0D+GlvH/
F98b9GOWVtEK3P77T65+/6dsqLW03eg3kIeHhyVzkCwSOskiEfh3AqQsSKto4cXQHa0/ZpVwOuvp
bX/MPbaPPipTG1qbBiEeoZ+irKPsxpCldOCKJW/bRfJl25qf3rmJwGA2M1NXMQFKbizbKRW5Xb3n
WXbrsO4nXY9ZZgnN3MMSfsQ0vzKtKzVUkKdaL1rkellP99OOm/2MPGjacBUbdBnAdj7sar16puOq
hlpL6+0buu08hflI2o6+3ouYpk60nmsDfXc6OXMP1sFN/2b/o4623kskrW1Nzb1tXTfu6k7M8f6L
UB/fv3P7ybWeywqrreXawNO+rjt35evZK+zx/Y7bfU97r13wfHw662luudo90NrW3t51/Ya02+3t
T1p7u1tafpys/wdArcLyalyV+AAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMS0xMC0xMVQwOToyOTow
MiswODowMCbawoMAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjEtMTAtMTFUMDk6Mjk6MDIrMDg6MDBX
h3o/AAAAIHRFWHRzb2Z0d2FyZQBodHRwczovL2ltYWdlbWFnaWNrLm9yZ7zPHZ0AAAAYdEVYdFRo
dW1iOjpEb2N1bWVudDo6UGFnZXMAMaf/uy8AAAAYdEVYdFRodW1iOjpJbWFnZTo6SGVpZ2h0ADI0
MBm70G0AAAAXdEVYdFRodW1iOjpJbWFnZTo6V2lkdGgAMjQwikqAMAAAABl0RVh0VGh1bWI6Ok1p
bWV0eXBlAGltYWdlL3BuZz+yVk4AAAAXdEVYdFRodW1iOjpNVGltZQAxNjMzOTE1NzQyH/RY+gAA
ABN0RVh0VGh1bWI6OlNpemUAMTEyMDVCQqJOJsUAAABGdEVYdFRodW1iOjpVUkkAZmlsZTovLy9h
cHAvdG1wL2ltYWdlbGMvaW1ndmlldzJfOV8xNjMxNzU3NTI1ODUwODk2Ml81OV9bMF3za0bbAAAA
AElFTkSuQmCC`

const planePath: string = `path://M1705.06,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705.06,1318.313z`

export default {
  male,
  female,
  robot,
  planePath,
}
