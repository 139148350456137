import axios from '@/request'
import axiosRetry from 'axios-retry'
import qs from 'qs'

axiosRetry(axios, { retries: 3 })

export default {
  async list(params: any) {
    return axios.get('stadium/list', { params })
  },
  async detail(params: any) {
    return axios.get('stadium/info', { params })
  },
  async settingDetail(params: any) {
    return axios.get('stadium-setting/detail', { params })
  },
  async todayBuy(params: any) {
    return axios.get('stadium-statistics/today-buy', { params })
  },
  async todayPlay(params: any) {
    return axios.get('stadium-statistics/today-play', { params })
  },
  async dailySituationInfo(params: any) {
    return axios.get('stadium-ground/daily-situation-info', { params })
  },
}
