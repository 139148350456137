var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"login-container"}},[_c('div',{attrs:{"id":"login-container-wrapper"}},[_vm._m(0),_c('div',{staticStyle:{"background":"#fff","width":"31.25vw","padding":"1.0417vw","margin":"0 auto"}},[_c('a-tabs',{attrs:{"type":"card","defaultActiveKey":"loginByQrcode"},on:{"change":_vm.changeTab}},[(_vm.showWxLogin)?_c('a-tab-pane',{key:"loginByQrcode",attrs:{"tab":"扫码登录"}},[_c('div',{staticStyle:{"text-align":"center"}},[_c('wxlogin',{attrs:{"appid":_vm.appid,"scope":_vm.scope,"redirect_uri":_vm.redirecturi,"href":"https://dv.taisam.cn/static/wx.css?t=3"}})],1)]):_vm._e(),_c('a-tab-pane',{key:"loginByPwd",attrs:{"tab":"密码登录"}},[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'account',
                  {
                    rules: [{ required: true, message: '请输入登录名!' }],
                  } ]),expression:"[\n                  'account',\n                  {\n                    rules: [{ required: true, message: '请输入登录名!' }],\n                  },\n                ]"}],attrs:{"placeholder":"登录名"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'password',
                  {
                    rules: [{ required: true, message: '请输入密码!' }],
                  } ]),expression:"[\n                  'password',\n                  {\n                    rules: [{ required: true, message: '请输入密码!' }],\n                  },\n                ]"}],attrs:{"type":"password","placeholder":"密码"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'verifyValue',
                      {
                        rules: [{ required: true, message: '请输入验证码' }],
                      } ]),expression:"[\n                      'verifyValue',\n                      {\n                        rules: [{ required: true, message: '请输入验证码' }],\n                      },\n                    ]"}],attrs:{"allowClear":"","placeholder":"验证码"}})],1),_c('a-col',{attrs:{"span":12}},[_c('img',{attrs:{"height":"32","src":_vm.captcha},on:{"click":_vm.refreshCaptcha}})])],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("登录")])],1)],1)],1)],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"text-align":"center","margin-top":"2.6042vw","margin-bottom":"2.6042vw"}},[_c('img',{attrs:{"src":"https://www.taisam.cn/resource/images/logo.png"}})])}]

export { render, staticRenderFns }