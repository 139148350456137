import axios from '@/request'
import axiosRetry from 'axios-retry'
import qs from 'qs'

axiosRetry(axios, { retries: 3 })

export default {
  async getBills(params: any) {
    return axios.get('bill/list', { params })
  },
  async detail(params: any) {
    return axios.get('bill/detail', { params })
  },
}
