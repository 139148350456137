



































































































































import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'

import MyList from '@/mixins/list'

// @ts-ignore
import MyDvPage from '$ui/dv/packages/my-dv-page'

// @ts-ignore
import MyDvBox from '$ui/dv/packages/my-dv-box'

// @ts-ignore
import MyDvBorder6 from '$ui/dv/packages/my-dv-border6'
// @ts-ignore
import MyDvTitle from '$ui/dv/packages/my-dv-title'
// @ts-ignore
import MyDvRing from '$ui/dv/packages/my-dv-ring'

import XEUtils from 'xe-utils'
import { Modal, VXETable } from 'vxe-table'
import 'vxe-table/lib/style.css'
import zhCN from 'vxe-table/lib/locale/lang/zh-CN'

VXETable.setup({
  i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(zhCN, key), args),
})

Vue.prototype.$XModal = VXETable.modal

Vue.use(Modal)

@Component({
  components: {
    MyDvPage,
    MyDvBox,
    MyDvBorder6,
    MyDvTitle,
    MyDvRing,
  },
})
export default class extends Mixins(MyList) {
  @Prop() stadiumId!: number

  @Prop() pageVisible!: boolean

  columns = ['阶段', '天数']
  rows = [
    ['签约', 1],
    ['勘查前准备', 1],
    ['勘查', 1],
    ['施工前准备', 1],
    ['施工', 2],
    ['验收', 1],
  ]

  modalShow() {
    // @ts-ignore
    this.$refs.modal1.maximize()
  }
}
