





import { Vue, Component, Mixins } from "vue-property-decorator";
import Echarts from "echarts";
import VueEcharts from "vue-echarts-ts";
Vue.use(VueEcharts);

@Component
export default class extends Mixins() {
  ei: any = {};

  option: any = {
    title: {
      text: "自定义雷达图"
    },
    legend: {
      data: ["图一", "图二", "张三", "李四"]
    },
    radar: [
      {
        indicator: [
          { text: "指标一" },
          { text: "指标二" },
          { text: "指标三" },
          { text: "指标四" },
          { text: "指标五" }
        ],
        // center: ["25%", "50%"],
        // radius: 120,
        // startAngle: 90,
        // splitNumber: 4,
        // shape: "circle",
        name: {
          formatter: "【{value}】",
          textStyle: {
            color: "#72ACD1"
          }
        },
        splitArea: {
          areaStyle: {
            color: [
              "rgba(114, 172, 209, 0.2)",
              "rgba(114, 172, 209, 0.4)",
              "rgba(114, 172, 209, 0.6)",
              "rgba(114, 172, 209, 0.8)",
              "rgba(114, 172, 209, 1)"
            ],
            shadowColor: "rgba(0, 0, 0, 0.3)",
            shadowBlur: 10
          }
        },
        axisLine: {
          lineStyle: {
            color: "rgba(255, 255, 255, 0.5)"
          }
        },
        splitLine: {
          lineStyle: {
            color: "rgba(255, 255, 255, 0.5)"
          }
        }
      }
    ],
    series: [
      {
        name: "雷达图",
        type: "radar",
        emphasis: {
          lineStyle: {
            width: 4
          }
        },
        data: [
          {
            value: [100, 8, 0.4, -80, 2000],
            name: "图一",
            symbol: "rect",
            symbolSize: 5,
            lineStyle: {
              type: "dashed"
            }
          },
          {
            value: [60, 5, 0.3, -100, 1500],
            name: "图二",
            areaStyle: {
              color: "rgba(255, 255, 255, 0.5)"
            }
          }
        ]
      }
    ]
  };
}
