<template>
  <a-config-provider :getPopupContainer="getPopupContainer" :locale="locale">
    <div id="app">
      <div @mouseenter="toggleBtn1" @mouseleave="toggleBtn2" class="btnWrap">
        <template v-if="btnVisible">
          <a-icon
            :style="{ zIndex: 214748364 }"
            type="fullscreen"
            v-if="fullscreen == false"
            @click="toggle"
          />
          <a-icon
            :style="{ zIndex: 214748364 }"
            type="fullscreen-exit"
            v-else
            @click="toggle"
          />

          <a-divider type="vertical"></a-divider>
          <a-icon
            :style="{ zIndex: 214748364 }"
            type="setting"
            @click="showSetting"
          />
        </template>
      </div>
      <fullscreen
        :fullscreen.sync="fullscreen"
        :teleport="teleport"
        :page-only="pageOnly"
        style="width: 100%; height: 100%"
      >
        <router-view />
      </fullscreen>
      <a-drawer
        :zIndex="214748364"
        title="Basic Drawer"
        placement="right"
        :closable="false"
        :visible="settingVisible"
        :after-visible-change="afterSettingVisibleChange"
        @close="onSettingClose"
      >
        <router-link to="/">
          <p>旧版</p>
        </router-link>
        <router-link to="/v3/">
          <p>新版</p>
        </router-link>
      </a-drawer>
    </div>
  </a-config-provider>
</template>
<script>
import moment from 'moment'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import 'moment/locale/zh-cn'

moment.locale('zh-cn')

export default {
  data() {
    return {
      locale: zhCN,
      fullscreen: false,
      teleport: true,
      pageOnly: false,
      btnVisible: false,
      settingVisible: false,
    }
  },
  methods: {
    toggle() {
      this.fullscreen = !this.fullscreen
    },
    toggleBtn1() {
      console.log('23')
      this.btnVisible = true
    },
    toggleBtn2() {
      this.btnVisible = false
    },
    afterSettingVisibleChange(val) {},
    showSetting() {
      this.settingVisible = true
    },
    onSettingClose() {
      this.settingVisible = false
    },
    getPopupContainer(el, dialogContext) {
      if (dialogContext) {
        return dialogContext.getDialogWrap()
      } else {
        return document.body
      }
    },
  },
}
</script>
<style>
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}

.btnWrap {
  position: absolute;
  width: 5.208vw;
  height: 2.083vw;
  top: 0.521vw;
  right: 0;
  z-index: 214748364;
  color: white;
  text-align: center;
}

.ant-table {
  color: #ffffff;
}

.ant-table-small {
  border: none;
}
.ant-table-small
  > .ant-table-content
  > .ant-table-header
  > table
  > .ant-table-thead
  > tr,
.ant-table-small
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr,
.ant-table-small
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-header
  > table
  > .ant-table-thead
  > tr,
.ant-table-small
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-left
  > .ant-table-header
  > table
  > .ant-table-thead
  > tr,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-right
  > .ant-table-header
  > table
  > .ant-table-thead
  > tr,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-left
  > .ant-table-body-outer
  > .ant-table-body-inner
  > table
  > .ant-table-thead
  > tr,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-right
  > .ant-table-body-outer
  > .ant-table-body-inner
  > table
  > .ant-table-thead
  > tr {
  border: none;
  background-image: -webkit-linear-gradient(
    left,
    rgba(96, 133, 255, 0.6),
    rgba(48, 69, 126, 0.6)
  ) !important;
}

.ant-table-thead > tr > th {
  background: transparent;
  color: #ffffff;
  border: none;
}

.ant-table-tbody > tr > td {
  border: none;
}

.ant-table-tbody > tr {
  background-color: rgba(96, 178, 255, 0.2) !important;
}
.ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-thead
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-thead
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: rgba(96, 178, 255, 0.5);
}

.ant-table-tbody > tr.ant-table-row-selected > td.ant-table-column-sort,
.ant-table-tbody > tr:hover.ant-table-row-selected > td,
.ant-table-tbody > tr:hover.ant-table-row-selected > td.ant-table-column-sort,
.ant-table-thead > tr.ant-table-row-selected > td.ant-table-column-sort,
.ant-table-thead > tr:hover.ant-table-row-selected > td,
.ant-table-thead > tr:hover.ant-table-row-selected > td.ant-table-column-sort {
  background: rgba(96, 178, 255, 0.8);
}
.ant-table-tbody > tr:nth-of-type(odd) {
  background-color: rgba(96, 178, 255, 0) !important;
}

.ant-pagination {
  color: rgba(13, 139, 189, 1);
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  color: rgba(13, 139, 189, 1);
}

.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  color: rgba(13, 139, 189, 1);
}

.ant-pagination-item a {
  color: rgba(13, 139, 189, 1);
}

.ant-pagination-prev a,
.ant-pagination-next a {
  color: rgba(13, 139, 189, 1);
}

.ant-pagination-disabled a,
.ant-pagination-disabled:hover a,
.ant-pagination-disabled:focus a,
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  color: rgba(13, 139, 189, 0.5);
}

.ant-list {
  color: #23f3ff;
}

.ant-list-sm .ant-list-item {
  /* border: none; */
  /* padding: 0; */
  border: rgba(61, 123, 186, 0.6) 0.052vw solid;
  margin-bottom: 0.313vw;
  padding: 0.313vw 0 0.313vw 0.313vw;
}

.ant-list-something-after-last-item
  .ant-spin-container
  > .ant-list-items
  > .ant-list-item:last-child {
  /* border: none; */
  border-bottom: rgba(61, 123, 186, 0.6) 0.052vw solid;
}

.ant-list-item-meta-title {
  color: #a1d3ff;
  font-size: 0.729vw;
}

.ant-list-item-meta-title > a {
  color: #a1d3ff;
  font-size: 0.729vw;
}

.ant-list-item-meta-description {
  color: #23f3ff;
  font-size: 0.625vw;
}

.ant-table-placeholder {
  background: transparent;
  /* border: none; */
}

.ant-empty-normal {
  color: #ffffff;
}

.ant-form-item-label > label {
  color: #ffffff;
}

.ant-table-placeholder {
  border-top: 0;
}

.ant-badge-status-text {
  color: #ffffff;
}

.ant-card {
  background: rgba(96, 178, 255, 0);
  color: #ffffff;
}

.ant-card-head {
  color: #ffffff;
}

.ant-card-type-inner .ant-card-head {
  background: rgba(96, 178, 255, 0);
}

.ant-dropdown,
.ant-modal-wrap,
.ant-popover,
.ant-drawer,
.ant-message,
.ant-notification,
.ant-select-dropdown,
.ant-menu-submenu-popup,
.ant-time-picker-panel,
.ant-calendar-picker-container,
.ant-tooltip,
.ant-cascader-menus {
  z-index: 2147483647 !important;
}

.vxe-modal--box {
  border: 0 !important;
}

tr:nth-child(2n) {
  background-color: unset !important;
}
</style>
