







import { Vue, Component, Mixins } from 'vue-property-decorator'

import Stadium from './stadium_new.vue'
@Component({
  components: {
    Stadium,
  },
})
export default class extends Mixins() {
  stadiumId: any = 260

  hideStadium() {
    return
  }
}
